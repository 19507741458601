html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

*::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: #e0e0e0;
}

*::-webkit-scrollbar-thumb {
  background: rgba(33, 33, 33, 0.25);
}

.m0 {
  margin: 0px !important;
}

.ml0 {
  margin-left: 0px !important;
}

.mr0 {
  margin-right: 0px !important;
}

.mt0 {
  margin-top: 0px !important;
}

.mb0 {
  margin-bottom: 0px !important;
}

.mx0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.my0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.m1 {
  margin: 1px !important;
}

.ml1 {
  margin-left: 1px !important;
}

.mr1 {
  margin-right: 1px !important;
}

.mt1 {
  margin-top: 1px !important;
}

.mb1 {
  margin-bottom: 1px !important;
}

.mx1 {
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.my1 {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.m2 {
  margin: 2px !important;
}

.ml2 {
  margin-left: 2px !important;
}

.mr2 {
  margin-right: 2px !important;
}

.mt2 {
  margin-top: 2px !important;
}

.mb2 {
  margin-bottom: 2px !important;
}

.mx2 {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.my2 {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.m3 {
  margin: 3px !important;
}

.ml3 {
  margin-left: 3px !important;
}

.mr3 {
  margin-right: 3px !important;
}

.mt3 {
  margin-top: 3px !important;
}

.mb3 {
  margin-bottom: 3px !important;
}

.mx3 {
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.my3 {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

.m4 {
  margin: 4px !important;
}

.ml4 {
  margin-left: 4px !important;
}

.mr4 {
  margin-right: 4px !important;
}

.mt4 {
  margin-top: 4px !important;
}

.mb4 {
  margin-bottom: 4px !important;
}

.mx4 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.my4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.m5 {
  margin: 5px !important;
}

.ml5 {
  margin-left: 5px !important;
}

.mr5 {
  margin-right: 5px !important;
}

.mt5 {
  margin-top: 5px !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

.mx5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.my5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.m6 {
  margin: 6px !important;
}

.ml6 {
  margin-left: 6px !important;
}

.mr6 {
  margin-right: 6px !important;
}

.mt6 {
  margin-top: 6px !important;
}

.mb6 {
  margin-bottom: 6px !important;
}

.mx6 {
  margin-left: 6px !important;
  margin-right: 6px !important;
}

.my6 {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.m7 {
  margin: 7px !important;
}

.ml7 {
  margin-left: 7px !important;
}

.mr7 {
  margin-right: 7px !important;
}

.mt7 {
  margin-top: 7px !important;
}

.mb7 {
  margin-bottom: 7px !important;
}

.mx7 {
  margin-left: 7px !important;
  margin-right: 7px !important;
}

.my7 {
  margin-top: 7px !important;
  margin-bottom: 7px !important;
}

.m8 {
  margin: 8px !important;
}

.ml8 {
  margin-left: 8px !important;
}

.mr8 {
  margin-right: 8px !important;
}

.mt8 {
  margin-top: 8px !important;
}

.mb8 {
  margin-bottom: 8px !important;
}

.mx8 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.my8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.m9 {
  margin: 9px !important;
}

.ml9 {
  margin-left: 9px !important;
}

.mr9 {
  margin-right: 9px !important;
}

.mt9 {
  margin-top: 9px !important;
}

.mb9 {
  margin-bottom: 9px !important;
}

.mx9 {
  margin-left: 9px !important;
  margin-right: 9px !important;
}

.my9 {
  margin-top: 9px !important;
  margin-bottom: 9px !important;
}

.m10 {
  margin: 10px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mx10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.my10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.m11 {
  margin: 11px !important;
}

.ml11 {
  margin-left: 11px !important;
}

.mr11 {
  margin-right: 11px !important;
}

.mt11 {
  margin-top: 11px !important;
}

.mb11 {
  margin-bottom: 11px !important;
}

.mx11 {
  margin-left: 11px !important;
  margin-right: 11px !important;
}

.my11 {
  margin-top: 11px !important;
  margin-bottom: 11px !important;
}

.m12 {
  margin: 12px !important;
}

.ml12 {
  margin-left: 12px !important;
}

.mr12 {
  margin-right: 12px !important;
}

.mt12 {
  margin-top: 12px !important;
}

.mb12 {
  margin-bottom: 12px !important;
}

.mx12 {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.my12 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.m13 {
  margin: 13px !important;
}

.ml13 {
  margin-left: 13px !important;
}

.mr13 {
  margin-right: 13px !important;
}

.mt13 {
  margin-top: 13px !important;
}

.mb13 {
  margin-bottom: 13px !important;
}

.mx13 {
  margin-left: 13px !important;
  margin-right: 13px !important;
}

.my13 {
  margin-top: 13px !important;
  margin-bottom: 13px !important;
}

.m14 {
  margin: 14px !important;
}

.ml14 {
  margin-left: 14px !important;
}

.mr14 {
  margin-right: 14px !important;
}

.mt14 {
  margin-top: 14px !important;
}

.mb14 {
  margin-bottom: 14px !important;
}

.mx14 {
  margin-left: 14px !important;
  margin-right: 14px !important;
}

.my14 {
  margin-top: 14px !important;
  margin-bottom: 14px !important;
}

.m15 {
  margin: 15px !important;
}

.ml15 {
  margin-left: 15px !important;
}

.mr15 {
  margin-right: 15px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mx15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.my15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.m16 {
  margin: 16px !important;
}

.ml16 {
  margin-left: 16px !important;
}

.mr16 {
  margin-right: 16px !important;
}

.mt16 {
  margin-top: 16px !important;
}

.mb16 {
  margin-bottom: 16px !important;
}

.mx16 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.my16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.m17 {
  margin: 17px !important;
}

.ml17 {
  margin-left: 17px !important;
}

.mr17 {
  margin-right: 17px !important;
}

.mt17 {
  margin-top: 17px !important;
}

.mb17 {
  margin-bottom: 17px !important;
}

.mx17 {
  margin-left: 17px !important;
  margin-right: 17px !important;
}

.my17 {
  margin-top: 17px !important;
  margin-bottom: 17px !important;
}

.m18 {
  margin: 18px !important;
}

.ml18 {
  margin-left: 18px !important;
}

.mr18 {
  margin-right: 18px !important;
}

.mt18 {
  margin-top: 18px !important;
}

.mb18 {
  margin-bottom: 18px !important;
}

.mx18 {
  margin-left: 18px !important;
  margin-right: 18px !important;
}

.my18 {
  margin-top: 18px !important;
  margin-bottom: 18px !important;
}

.m19 {
  margin: 19px !important;
}

.ml19 {
  margin-left: 19px !important;
}

.mr19 {
  margin-right: 19px !important;
}

.mt19 {
  margin-top: 19px !important;
}

.mb19 {
  margin-bottom: 19px !important;
}

.mx19 {
  margin-left: 19px !important;
  margin-right: 19px !important;
}

.my19 {
  margin-top: 19px !important;
  margin-bottom: 19px !important;
}

.m20 {
  margin: 20px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mx20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.my20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.m21 {
  margin: 21px !important;
}

.ml21 {
  margin-left: 21px !important;
}

.mr21 {
  margin-right: 21px !important;
}

.mt21 {
  margin-top: 21px !important;
}

.mb21 {
  margin-bottom: 21px !important;
}

.mx21 {
  margin-left: 21px !important;
  margin-right: 21px !important;
}

.my21 {
  margin-top: 21px !important;
  margin-bottom: 21px !important;
}

.m22 {
  margin: 22px !important;
}

.ml22 {
  margin-left: 22px !important;
}

.mr22 {
  margin-right: 22px !important;
}

.mt22 {
  margin-top: 22px !important;
}

.mb22 {
  margin-bottom: 22px !important;
}

.mx22 {
  margin-left: 22px !important;
  margin-right: 22px !important;
}

.my22 {
  margin-top: 22px !important;
  margin-bottom: 22px !important;
}

.m23 {
  margin: 23px !important;
}

.ml23 {
  margin-left: 23px !important;
}

.mr23 {
  margin-right: 23px !important;
}

.mt23 {
  margin-top: 23px !important;
}

.mb23 {
  margin-bottom: 23px !important;
}

.mx23 {
  margin-left: 23px !important;
  margin-right: 23px !important;
}

.my23 {
  margin-top: 23px !important;
  margin-bottom: 23px !important;
}

.m24 {
  margin: 24px !important;
}

.ml24 {
  margin-left: 24px !important;
}

.mr24 {
  margin-right: 24px !important;
}

.mt24 {
  margin-top: 24px !important;
}

.mb24 {
  margin-bottom: 24px !important;
}

.mx24 {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.my24 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.m25 {
  margin: 25px !important;
}

.ml25 {
  margin-left: 25px !important;
}

.mr25 {
  margin-right: 25px !important;
}

.mt25 {
  margin-top: 25px !important;
}

.mb25 {
  margin-bottom: 25px !important;
}

.mx25 {
  margin-left: 25px !important;
  margin-right: 25px !important;
}

.my25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.m26 {
  margin: 26px !important;
}

.ml26 {
  margin-left: 26px !important;
}

.mr26 {
  margin-right: 26px !important;
}

.mt26 {
  margin-top: 26px !important;
}

.mb26 {
  margin-bottom: 26px !important;
}

.mx26 {
  margin-left: 26px !important;
  margin-right: 26px !important;
}

.my26 {
  margin-top: 26px !important;
  margin-bottom: 26px !important;
}

.m27 {
  margin: 27px !important;
}

.ml27 {
  margin-left: 27px !important;
}

.mr27 {
  margin-right: 27px !important;
}

.mt27 {
  margin-top: 27px !important;
}

.mb27 {
  margin-bottom: 27px !important;
}

.mx27 {
  margin-left: 27px !important;
  margin-right: 27px !important;
}

.my27 {
  margin-top: 27px !important;
  margin-bottom: 27px !important;
}

.m28 {
  margin: 28px !important;
}

.ml28 {
  margin-left: 28px !important;
}

.mr28 {
  margin-right: 28px !important;
}

.mt28 {
  margin-top: 28px !important;
}

.mb28 {
  margin-bottom: 28px !important;
}

.mx28 {
  margin-left: 28px !important;
  margin-right: 28px !important;
}

.my28 {
  margin-top: 28px !important;
  margin-bottom: 28px !important;
}

.m29 {
  margin: 29px !important;
}

.ml29 {
  margin-left: 29px !important;
}

.mr29 {
  margin-right: 29px !important;
}

.mt29 {
  margin-top: 29px !important;
}

.mb29 {
  margin-bottom: 29px !important;
}

.mx29 {
  margin-left: 29px !important;
  margin-right: 29px !important;
}

.my29 {
  margin-top: 29px !important;
  margin-bottom: 29px !important;
}

.m30 {
  margin: 30px !important;
}

.ml30 {
  margin-left: 30px !important;
}

.mr30 {
  margin-right: 30px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mx30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.my30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.m31 {
  margin: 31px !important;
}

.ml31 {
  margin-left: 31px !important;
}

.mr31 {
  margin-right: 31px !important;
}

.mt31 {
  margin-top: 31px !important;
}

.mb31 {
  margin-bottom: 31px !important;
}

.mx31 {
  margin-left: 31px !important;
  margin-right: 31px !important;
}

.my31 {
  margin-top: 31px !important;
  margin-bottom: 31px !important;
}

.m32 {
  margin: 32px !important;
}

.ml32 {
  margin-left: 32px !important;
}

.mr32 {
  margin-right: 32px !important;
}

.mt32 {
  margin-top: 32px !important;
}

.mb32 {
  margin-bottom: 32px !important;
}

.mx32 {
  margin-left: 32px !important;
  margin-right: 32px !important;
}

.my32 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.m33 {
  margin: 33px !important;
}

.ml33 {
  margin-left: 33px !important;
}

.mr33 {
  margin-right: 33px !important;
}

.mt33 {
  margin-top: 33px !important;
}

.mb33 {
  margin-bottom: 33px !important;
}

.mx33 {
  margin-left: 33px !important;
  margin-right: 33px !important;
}

.my33 {
  margin-top: 33px !important;
  margin-bottom: 33px !important;
}

.m34 {
  margin: 34px !important;
}

.ml34 {
  margin-left: 34px !important;
}

.mr34 {
  margin-right: 34px !important;
}

.mt34 {
  margin-top: 34px !important;
}

.mb34 {
  margin-bottom: 34px !important;
}

.mx34 {
  margin-left: 34px !important;
  margin-right: 34px !important;
}

.my34 {
  margin-top: 34px !important;
  margin-bottom: 34px !important;
}

.m35 {
  margin: 35px !important;
}

.ml35 {
  margin-left: 35px !important;
}

.mr35 {
  margin-right: 35px !important;
}

.mt35 {
  margin-top: 35px !important;
}

.mb35 {
  margin-bottom: 35px !important;
}

.mx35 {
  margin-left: 35px !important;
  margin-right: 35px !important;
}

.my35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.m36 {
  margin: 36px !important;
}

.ml36 {
  margin-left: 36px !important;
}

.mr36 {
  margin-right: 36px !important;
}

.mt36 {
  margin-top: 36px !important;
}

.mb36 {
  margin-bottom: 36px !important;
}

.mx36 {
  margin-left: 36px !important;
  margin-right: 36px !important;
}

.my36 {
  margin-top: 36px !important;
  margin-bottom: 36px !important;
}

.m37 {
  margin: 37px !important;
}

.ml37 {
  margin-left: 37px !important;
}

.mr37 {
  margin-right: 37px !important;
}

.mt37 {
  margin-top: 37px !important;
}

.mb37 {
  margin-bottom: 37px !important;
}

.mx37 {
  margin-left: 37px !important;
  margin-right: 37px !important;
}

.my37 {
  margin-top: 37px !important;
  margin-bottom: 37px !important;
}

.m38 {
  margin: 38px !important;
}

.ml38 {
  margin-left: 38px !important;
}

.mr38 {
  margin-right: 38px !important;
}

.mt38 {
  margin-top: 38px !important;
}

.mb38 {
  margin-bottom: 38px !important;
}

.mx38 {
  margin-left: 38px !important;
  margin-right: 38px !important;
}

.my38 {
  margin-top: 38px !important;
  margin-bottom: 38px !important;
}

.m39 {
  margin: 39px !important;
}

.ml39 {
  margin-left: 39px !important;
}

.mr39 {
  margin-right: 39px !important;
}

.mt39 {
  margin-top: 39px !important;
}

.mb39 {
  margin-bottom: 39px !important;
}

.mx39 {
  margin-left: 39px !important;
  margin-right: 39px !important;
}

.my39 {
  margin-top: 39px !important;
  margin-bottom: 39px !important;
}

.m40 {
  margin: 40px !important;
}

.ml40 {
  margin-left: 40px !important;
}

.mr40 {
  margin-right: 40px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.mx40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.my40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.m41 {
  margin: 41px !important;
}

.ml41 {
  margin-left: 41px !important;
}

.mr41 {
  margin-right: 41px !important;
}

.mt41 {
  margin-top: 41px !important;
}

.mb41 {
  margin-bottom: 41px !important;
}

.mx41 {
  margin-left: 41px !important;
  margin-right: 41px !important;
}

.my41 {
  margin-top: 41px !important;
  margin-bottom: 41px !important;
}

.m42 {
  margin: 42px !important;
}

.ml42 {
  margin-left: 42px !important;
}

.mr42 {
  margin-right: 42px !important;
}

.mt42 {
  margin-top: 42px !important;
}

.mb42 {
  margin-bottom: 42px !important;
}

.mx42 {
  margin-left: 42px !important;
  margin-right: 42px !important;
}

.my42 {
  margin-top: 42px !important;
  margin-bottom: 42px !important;
}

.m43 {
  margin: 43px !important;
}

.ml43 {
  margin-left: 43px !important;
}

.mr43 {
  margin-right: 43px !important;
}

.mt43 {
  margin-top: 43px !important;
}

.mb43 {
  margin-bottom: 43px !important;
}

.mx43 {
  margin-left: 43px !important;
  margin-right: 43px !important;
}

.my43 {
  margin-top: 43px !important;
  margin-bottom: 43px !important;
}

.m44 {
  margin: 44px !important;
}

.ml44 {
  margin-left: 44px !important;
}

.mr44 {
  margin-right: 44px !important;
}

.mt44 {
  margin-top: 44px !important;
}

.mb44 {
  margin-bottom: 44px !important;
}

.mx44 {
  margin-left: 44px !important;
  margin-right: 44px !important;
}

.my44 {
  margin-top: 44px !important;
  margin-bottom: 44px !important;
}

.m45 {
  margin: 45px !important;
}

.ml45 {
  margin-left: 45px !important;
}

.mr45 {
  margin-right: 45px !important;
}

.mt45 {
  margin-top: 45px !important;
}

.mb45 {
  margin-bottom: 45px !important;
}

.mx45 {
  margin-left: 45px !important;
  margin-right: 45px !important;
}

.my45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

.m46 {
  margin: 46px !important;
}

.ml46 {
  margin-left: 46px !important;
}

.mr46 {
  margin-right: 46px !important;
}

.mt46 {
  margin-top: 46px !important;
}

.mb46 {
  margin-bottom: 46px !important;
}

.mx46 {
  margin-left: 46px !important;
  margin-right: 46px !important;
}

.my46 {
  margin-top: 46px !important;
  margin-bottom: 46px !important;
}

.m47 {
  margin: 47px !important;
}

.ml47 {
  margin-left: 47px !important;
}

.mr47 {
  margin-right: 47px !important;
}

.mt47 {
  margin-top: 47px !important;
}

.mb47 {
  margin-bottom: 47px !important;
}

.mx47 {
  margin-left: 47px !important;
  margin-right: 47px !important;
}

.my47 {
  margin-top: 47px !important;
  margin-bottom: 47px !important;
}

.m48 {
  margin: 48px !important;
}

.ml48 {
  margin-left: 48px !important;
}

.mr48 {
  margin-right: 48px !important;
}

.mt48 {
  margin-top: 48px !important;
}

.mb48 {
  margin-bottom: 48px !important;
}

.mx48 {
  margin-left: 48px !important;
  margin-right: 48px !important;
}

.my48 {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}

.m49 {
  margin: 49px !important;
}

.ml49 {
  margin-left: 49px !important;
}

.mr49 {
  margin-right: 49px !important;
}

.mt49 {
  margin-top: 49px !important;
}

.mb49 {
  margin-bottom: 49px !important;
}

.mx49 {
  margin-left: 49px !important;
  margin-right: 49px !important;
}

.my49 {
  margin-top: 49px !important;
  margin-bottom: 49px !important;
}

.m50 {
  margin: 50px !important;
}

.ml50 {
  margin-left: 50px !important;
}

.mr50 {
  margin-right: 50px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.mx50 {
  margin-left: 50px !important;
  margin-right: 50px !important;
}

.my50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.m51 {
  margin: 51px !important;
}

.ml51 {
  margin-left: 51px !important;
}

.mr51 {
  margin-right: 51px !important;
}

.mt51 {
  margin-top: 51px !important;
}

.mb51 {
  margin-bottom: 51px !important;
}

.mx51 {
  margin-left: 51px !important;
  margin-right: 51px !important;
}

.my51 {
  margin-top: 51px !important;
  margin-bottom: 51px !important;
}

.m52 {
  margin: 52px !important;
}

.ml52 {
  margin-left: 52px !important;
}

.mr52 {
  margin-right: 52px !important;
}

.mt52 {
  margin-top: 52px !important;
}

.mb52 {
  margin-bottom: 52px !important;
}

.mx52 {
  margin-left: 52px !important;
  margin-right: 52px !important;
}

.my52 {
  margin-top: 52px !important;
  margin-bottom: 52px !important;
}

.m53 {
  margin: 53px !important;
}

.ml53 {
  margin-left: 53px !important;
}

.mr53 {
  margin-right: 53px !important;
}

.mt53 {
  margin-top: 53px !important;
}

.mb53 {
  margin-bottom: 53px !important;
}

.mx53 {
  margin-left: 53px !important;
  margin-right: 53px !important;
}

.my53 {
  margin-top: 53px !important;
  margin-bottom: 53px !important;
}

.m54 {
  margin: 54px !important;
}

.ml54 {
  margin-left: 54px !important;
}

.mr54 {
  margin-right: 54px !important;
}

.mt54 {
  margin-top: 54px !important;
}

.mb54 {
  margin-bottom: 54px !important;
}

.mx54 {
  margin-left: 54px !important;
  margin-right: 54px !important;
}

.my54 {
  margin-top: 54px !important;
  margin-bottom: 54px !important;
}

.m55 {
  margin: 55px !important;
}

.ml55 {
  margin-left: 55px !important;
}

.mr55 {
  margin-right: 55px !important;
}

.mt55 {
  margin-top: 55px !important;
}

.mb55 {
  margin-bottom: 55px !important;
}

.mx55 {
  margin-left: 55px !important;
  margin-right: 55px !important;
}

.my55 {
  margin-top: 55px !important;
  margin-bottom: 55px !important;
}

.m56 {
  margin: 56px !important;
}

.ml56 {
  margin-left: 56px !important;
}

.mr56 {
  margin-right: 56px !important;
}

.mt56 {
  margin-top: 56px !important;
}

.mb56 {
  margin-bottom: 56px !important;
}

.mx56 {
  margin-left: 56px !important;
  margin-right: 56px !important;
}

.my56 {
  margin-top: 56px !important;
  margin-bottom: 56px !important;
}

.m57 {
  margin: 57px !important;
}

.ml57 {
  margin-left: 57px !important;
}

.mr57 {
  margin-right: 57px !important;
}

.mt57 {
  margin-top: 57px !important;
}

.mb57 {
  margin-bottom: 57px !important;
}

.mx57 {
  margin-left: 57px !important;
  margin-right: 57px !important;
}

.my57 {
  margin-top: 57px !important;
  margin-bottom: 57px !important;
}

.m58 {
  margin: 58px !important;
}

.ml58 {
  margin-left: 58px !important;
}

.mr58 {
  margin-right: 58px !important;
}

.mt58 {
  margin-top: 58px !important;
}

.mb58 {
  margin-bottom: 58px !important;
}

.mx58 {
  margin-left: 58px !important;
  margin-right: 58px !important;
}

.my58 {
  margin-top: 58px !important;
  margin-bottom: 58px !important;
}

.m59 {
  margin: 59px !important;
}

.ml59 {
  margin-left: 59px !important;
}

.mr59 {
  margin-right: 59px !important;
}

.mt59 {
  margin-top: 59px !important;
}

.mb59 {
  margin-bottom: 59px !important;
}

.mx59 {
  margin-left: 59px !important;
  margin-right: 59px !important;
}

.my59 {
  margin-top: 59px !important;
  margin-bottom: 59px !important;
}

.m60 {
  margin: 60px !important;
}

.ml60 {
  margin-left: 60px !important;
}

.mr60 {
  margin-right: 60px !important;
}

.mt60 {
  margin-top: 60px !important;
}

.mb60 {
  margin-bottom: 60px !important;
}

.mx60 {
  margin-left: 60px !important;
  margin-right: 60px !important;
}

.my60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.m61 {
  margin: 61px !important;
}

.ml61 {
  margin-left: 61px !important;
}

.mr61 {
  margin-right: 61px !important;
}

.mt61 {
  margin-top: 61px !important;
}

.mb61 {
  margin-bottom: 61px !important;
}

.mx61 {
  margin-left: 61px !important;
  margin-right: 61px !important;
}

.my61 {
  margin-top: 61px !important;
  margin-bottom: 61px !important;
}

.m62 {
  margin: 62px !important;
}

.ml62 {
  margin-left: 62px !important;
}

.mr62 {
  margin-right: 62px !important;
}

.mt62 {
  margin-top: 62px !important;
}

.mb62 {
  margin-bottom: 62px !important;
}

.mx62 {
  margin-left: 62px !important;
  margin-right: 62px !important;
}

.my62 {
  margin-top: 62px !important;
  margin-bottom: 62px !important;
}

.m63 {
  margin: 63px !important;
}

.ml63 {
  margin-left: 63px !important;
}

.mr63 {
  margin-right: 63px !important;
}

.mt63 {
  margin-top: 63px !important;
}

.mb63 {
  margin-bottom: 63px !important;
}

.mx63 {
  margin-left: 63px !important;
  margin-right: 63px !important;
}

.my63 {
  margin-top: 63px !important;
  margin-bottom: 63px !important;
}

.m64 {
  margin: 64px !important;
}

.ml64 {
  margin-left: 64px !important;
}

.mr64 {
  margin-right: 64px !important;
}

.mt64 {
  margin-top: 64px !important;
}

.mb64 {
  margin-bottom: 64px !important;
}

.mx64 {
  margin-left: 64px !important;
  margin-right: 64px !important;
}

.my64 {
  margin-top: 64px !important;
  margin-bottom: 64px !important;
}

.m65 {
  margin: 65px !important;
}

.ml65 {
  margin-left: 65px !important;
}

.mr65 {
  margin-right: 65px !important;
}

.mt65 {
  margin-top: 65px !important;
}

.mb65 {
  margin-bottom: 65px !important;
}

.mx65 {
  margin-left: 65px !important;
  margin-right: 65px !important;
}

.my65 {
  margin-top: 65px !important;
  margin-bottom: 65px !important;
}

.m66 {
  margin: 66px !important;
}

.ml66 {
  margin-left: 66px !important;
}

.mr66 {
  margin-right: 66px !important;
}

.mt66 {
  margin-top: 66px !important;
}

.mb66 {
  margin-bottom: 66px !important;
}

.mx66 {
  margin-left: 66px !important;
  margin-right: 66px !important;
}

.my66 {
  margin-top: 66px !important;
  margin-bottom: 66px !important;
}

.m67 {
  margin: 67px !important;
}

.ml67 {
  margin-left: 67px !important;
}

.mr67 {
  margin-right: 67px !important;
}

.mt67 {
  margin-top: 67px !important;
}

.mb67 {
  margin-bottom: 67px !important;
}

.mx67 {
  margin-left: 67px !important;
  margin-right: 67px !important;
}

.my67 {
  margin-top: 67px !important;
  margin-bottom: 67px !important;
}

.m68 {
  margin: 68px !important;
}

.ml68 {
  margin-left: 68px !important;
}

.mr68 {
  margin-right: 68px !important;
}

.mt68 {
  margin-top: 68px !important;
}

.mb68 {
  margin-bottom: 68px !important;
}

.mx68 {
  margin-left: 68px !important;
  margin-right: 68px !important;
}

.my68 {
  margin-top: 68px !important;
  margin-bottom: 68px !important;
}

.m69 {
  margin: 69px !important;
}

.ml69 {
  margin-left: 69px !important;
}

.mr69 {
  margin-right: 69px !important;
}

.mt69 {
  margin-top: 69px !important;
}

.mb69 {
  margin-bottom: 69px !important;
}

.mx69 {
  margin-left: 69px !important;
  margin-right: 69px !important;
}

.my69 {
  margin-top: 69px !important;
  margin-bottom: 69px !important;
}

.m70 {
  margin: 70px !important;
}

.ml70 {
  margin-left: 70px !important;
}

.mr70 {
  margin-right: 70px !important;
}

.mt70 {
  margin-top: 70px !important;
}

.mb70 {
  margin-bottom: 70px !important;
}

.mx70 {
  margin-left: 70px !important;
  margin-right: 70px !important;
}

.my70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}

.m71 {
  margin: 71px !important;
}

.ml71 {
  margin-left: 71px !important;
}

.mr71 {
  margin-right: 71px !important;
}

.mt71 {
  margin-top: 71px !important;
}

.mb71 {
  margin-bottom: 71px !important;
}

.mx71 {
  margin-left: 71px !important;
  margin-right: 71px !important;
}

.my71 {
  margin-top: 71px !important;
  margin-bottom: 71px !important;
}

.m72 {
  margin: 72px !important;
}

.ml72 {
  margin-left: 72px !important;
}

.mr72 {
  margin-right: 72px !important;
}

.mt72 {
  margin-top: 72px !important;
}

.mb72 {
  margin-bottom: 72px !important;
}

.mx72 {
  margin-left: 72px !important;
  margin-right: 72px !important;
}

.my72 {
  margin-top: 72px !important;
  margin-bottom: 72px !important;
}

.m73 {
  margin: 73px !important;
}

.ml73 {
  margin-left: 73px !important;
}

.mr73 {
  margin-right: 73px !important;
}

.mt73 {
  margin-top: 73px !important;
}

.mb73 {
  margin-bottom: 73px !important;
}

.mx73 {
  margin-left: 73px !important;
  margin-right: 73px !important;
}

.my73 {
  margin-top: 73px !important;
  margin-bottom: 73px !important;
}

.m74 {
  margin: 74px !important;
}

.ml74 {
  margin-left: 74px !important;
}

.mr74 {
  margin-right: 74px !important;
}

.mt74 {
  margin-top: 74px !important;
}

.mb74 {
  margin-bottom: 74px !important;
}

.mx74 {
  margin-left: 74px !important;
  margin-right: 74px !important;
}

.my74 {
  margin-top: 74px !important;
  margin-bottom: 74px !important;
}

.m75 {
  margin: 75px !important;
}

.ml75 {
  margin-left: 75px !important;
}

.mr75 {
  margin-right: 75px !important;
}

.mt75 {
  margin-top: 75px !important;
}

.mb75 {
  margin-bottom: 75px !important;
}

.mx75 {
  margin-left: 75px !important;
  margin-right: 75px !important;
}

.my75 {
  margin-top: 75px !important;
  margin-bottom: 75px !important;
}

.m76 {
  margin: 76px !important;
}

.ml76 {
  margin-left: 76px !important;
}

.mr76 {
  margin-right: 76px !important;
}

.mt76 {
  margin-top: 76px !important;
}

.mb76 {
  margin-bottom: 76px !important;
}

.mx76 {
  margin-left: 76px !important;
  margin-right: 76px !important;
}

.my76 {
  margin-top: 76px !important;
  margin-bottom: 76px !important;
}

.m77 {
  margin: 77px !important;
}

.ml77 {
  margin-left: 77px !important;
}

.mr77 {
  margin-right: 77px !important;
}

.mt77 {
  margin-top: 77px !important;
}

.mb77 {
  margin-bottom: 77px !important;
}

.mx77 {
  margin-left: 77px !important;
  margin-right: 77px !important;
}

.my77 {
  margin-top: 77px !important;
  margin-bottom: 77px !important;
}

.m78 {
  margin: 78px !important;
}

.ml78 {
  margin-left: 78px !important;
}

.mr78 {
  margin-right: 78px !important;
}

.mt78 {
  margin-top: 78px !important;
}

.mb78 {
  margin-bottom: 78px !important;
}

.mx78 {
  margin-left: 78px !important;
  margin-right: 78px !important;
}

.my78 {
  margin-top: 78px !important;
  margin-bottom: 78px !important;
}

.m79 {
  margin: 79px !important;
}

.ml79 {
  margin-left: 79px !important;
}

.mr79 {
  margin-right: 79px !important;
}

.mt79 {
  margin-top: 79px !important;
}

.mb79 {
  margin-bottom: 79px !important;
}

.mx79 {
  margin-left: 79px !important;
  margin-right: 79px !important;
}

.my79 {
  margin-top: 79px !important;
  margin-bottom: 79px !important;
}

.m80 {
  margin: 80px !important;
}

.ml80 {
  margin-left: 80px !important;
}

.mr80 {
  margin-right: 80px !important;
}

.mt80 {
  margin-top: 80px !important;
}

.mb80 {
  margin-bottom: 80px !important;
}

.mx80 {
  margin-left: 80px !important;
  margin-right: 80px !important;
}

.my80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.m81 {
  margin: 81px !important;
}

.ml81 {
  margin-left: 81px !important;
}

.mr81 {
  margin-right: 81px !important;
}

.mt81 {
  margin-top: 81px !important;
}

.mb81 {
  margin-bottom: 81px !important;
}

.mx81 {
  margin-left: 81px !important;
  margin-right: 81px !important;
}

.my81 {
  margin-top: 81px !important;
  margin-bottom: 81px !important;
}

.m82 {
  margin: 82px !important;
}

.ml82 {
  margin-left: 82px !important;
}

.mr82 {
  margin-right: 82px !important;
}

.mt82 {
  margin-top: 82px !important;
}

.mb82 {
  margin-bottom: 82px !important;
}

.mx82 {
  margin-left: 82px !important;
  margin-right: 82px !important;
}

.my82 {
  margin-top: 82px !important;
  margin-bottom: 82px !important;
}

.m83 {
  margin: 83px !important;
}

.ml83 {
  margin-left: 83px !important;
}

.mr83 {
  margin-right: 83px !important;
}

.mt83 {
  margin-top: 83px !important;
}

.mb83 {
  margin-bottom: 83px !important;
}

.mx83 {
  margin-left: 83px !important;
  margin-right: 83px !important;
}

.my83 {
  margin-top: 83px !important;
  margin-bottom: 83px !important;
}

.m84 {
  margin: 84px !important;
}

.ml84 {
  margin-left: 84px !important;
}

.mr84 {
  margin-right: 84px !important;
}

.mt84 {
  margin-top: 84px !important;
}

.mb84 {
  margin-bottom: 84px !important;
}

.mx84 {
  margin-left: 84px !important;
  margin-right: 84px !important;
}

.my84 {
  margin-top: 84px !important;
  margin-bottom: 84px !important;
}

.m85 {
  margin: 85px !important;
}

.ml85 {
  margin-left: 85px !important;
}

.mr85 {
  margin-right: 85px !important;
}

.mt85 {
  margin-top: 85px !important;
}

.mb85 {
  margin-bottom: 85px !important;
}

.mx85 {
  margin-left: 85px !important;
  margin-right: 85px !important;
}

.my85 {
  margin-top: 85px !important;
  margin-bottom: 85px !important;
}

.m86 {
  margin: 86px !important;
}

.ml86 {
  margin-left: 86px !important;
}

.mr86 {
  margin-right: 86px !important;
}

.mt86 {
  margin-top: 86px !important;
}

.mb86 {
  margin-bottom: 86px !important;
}

.mx86 {
  margin-left: 86px !important;
  margin-right: 86px !important;
}

.my86 {
  margin-top: 86px !important;
  margin-bottom: 86px !important;
}

.m87 {
  margin: 87px !important;
}

.ml87 {
  margin-left: 87px !important;
}

.mr87 {
  margin-right: 87px !important;
}

.mt87 {
  margin-top: 87px !important;
}

.mb87 {
  margin-bottom: 87px !important;
}

.mx87 {
  margin-left: 87px !important;
  margin-right: 87px !important;
}

.my87 {
  margin-top: 87px !important;
  margin-bottom: 87px !important;
}

.m88 {
  margin: 88px !important;
}

.ml88 {
  margin-left: 88px !important;
}

.mr88 {
  margin-right: 88px !important;
}

.mt88 {
  margin-top: 88px !important;
}

.mb88 {
  margin-bottom: 88px !important;
}

.mx88 {
  margin-left: 88px !important;
  margin-right: 88px !important;
}

.my88 {
  margin-top: 88px !important;
  margin-bottom: 88px !important;
}

.m89 {
  margin: 89px !important;
}

.ml89 {
  margin-left: 89px !important;
}

.mr89 {
  margin-right: 89px !important;
}

.mt89 {
  margin-top: 89px !important;
}

.mb89 {
  margin-bottom: 89px !important;
}

.mx89 {
  margin-left: 89px !important;
  margin-right: 89px !important;
}

.my89 {
  margin-top: 89px !important;
  margin-bottom: 89px !important;
}

.m90 {
  margin: 90px !important;
}

.ml90 {
  margin-left: 90px !important;
}

.mr90 {
  margin-right: 90px !important;
}

.mt90 {
  margin-top: 90px !important;
}

.mb90 {
  margin-bottom: 90px !important;
}

.mx90 {
  margin-left: 90px !important;
  margin-right: 90px !important;
}

.my90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}

.m91 {
  margin: 91px !important;
}

.ml91 {
  margin-left: 91px !important;
}

.mr91 {
  margin-right: 91px !important;
}

.mt91 {
  margin-top: 91px !important;
}

.mb91 {
  margin-bottom: 91px !important;
}

.mx91 {
  margin-left: 91px !important;
  margin-right: 91px !important;
}

.my91 {
  margin-top: 91px !important;
  margin-bottom: 91px !important;
}

.m92 {
  margin: 92px !important;
}

.ml92 {
  margin-left: 92px !important;
}

.mr92 {
  margin-right: 92px !important;
}

.mt92 {
  margin-top: 92px !important;
}

.mb92 {
  margin-bottom: 92px !important;
}

.mx92 {
  margin-left: 92px !important;
  margin-right: 92px !important;
}

.my92 {
  margin-top: 92px !important;
  margin-bottom: 92px !important;
}

.m93 {
  margin: 93px !important;
}

.ml93 {
  margin-left: 93px !important;
}

.mr93 {
  margin-right: 93px !important;
}

.mt93 {
  margin-top: 93px !important;
}

.mb93 {
  margin-bottom: 93px !important;
}

.mx93 {
  margin-left: 93px !important;
  margin-right: 93px !important;
}

.my93 {
  margin-top: 93px !important;
  margin-bottom: 93px !important;
}

.m94 {
  margin: 94px !important;
}

.ml94 {
  margin-left: 94px !important;
}

.mr94 {
  margin-right: 94px !important;
}

.mt94 {
  margin-top: 94px !important;
}

.mb94 {
  margin-bottom: 94px !important;
}

.mx94 {
  margin-left: 94px !important;
  margin-right: 94px !important;
}

.my94 {
  margin-top: 94px !important;
  margin-bottom: 94px !important;
}

.m95 {
  margin: 95px !important;
}

.ml95 {
  margin-left: 95px !important;
}

.mr95 {
  margin-right: 95px !important;
}

.mt95 {
  margin-top: 95px !important;
}

.mb95 {
  margin-bottom: 95px !important;
}

.mx95 {
  margin-left: 95px !important;
  margin-right: 95px !important;
}

.my95 {
  margin-top: 95px !important;
  margin-bottom: 95px !important;
}

.m96 {
  margin: 96px !important;
}

.ml96 {
  margin-left: 96px !important;
}

.mr96 {
  margin-right: 96px !important;
}

.mt96 {
  margin-top: 96px !important;
}

.mb96 {
  margin-bottom: 96px !important;
}

.mx96 {
  margin-left: 96px !important;
  margin-right: 96px !important;
}

.my96 {
  margin-top: 96px !important;
  margin-bottom: 96px !important;
}

.m97 {
  margin: 97px !important;
}

.ml97 {
  margin-left: 97px !important;
}

.mr97 {
  margin-right: 97px !important;
}

.mt97 {
  margin-top: 97px !important;
}

.mb97 {
  margin-bottom: 97px !important;
}

.mx97 {
  margin-left: 97px !important;
  margin-right: 97px !important;
}

.my97 {
  margin-top: 97px !important;
  margin-bottom: 97px !important;
}

.m98 {
  margin: 98px !important;
}

.ml98 {
  margin-left: 98px !important;
}

.mr98 {
  margin-right: 98px !important;
}

.mt98 {
  margin-top: 98px !important;
}

.mb98 {
  margin-bottom: 98px !important;
}

.mx98 {
  margin-left: 98px !important;
  margin-right: 98px !important;
}

.my98 {
  margin-top: 98px !important;
  margin-bottom: 98px !important;
}

.m99 {
  margin: 99px !important;
}

.ml99 {
  margin-left: 99px !important;
}

.mr99 {
  margin-right: 99px !important;
}

.mt99 {
  margin-top: 99px !important;
}

.mb99 {
  margin-bottom: 99px !important;
}

.mx99 {
  margin-left: 99px !important;
  margin-right: 99px !important;
}

.my99 {
  margin-top: 99px !important;
  margin-bottom: 99px !important;
}

.m100 {
  margin: 100px !important;
}

.ml100 {
  margin-left: 100px !important;
}

.mr100 {
  margin-right: 100px !important;
}

.mt100 {
  margin-top: 100px !important;
}

.mb100 {
  margin-bottom: 100px !important;
}

.mx100 {
  margin-left: 100px !important;
  margin-right: 100px !important;
}

.my100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

.p0 {
  padding: 0px !important;
}

.pl0 {
  padding-left: 0px !important;
}

.pr0 {
  padding-right: 0px !important;
}

.pt0 {
  padding-top: 0px !important;
}

.pb0 {
  padding-bottom: 0px !important;
}

.px0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.py0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.p1 {
  padding: 1px !important;
}

.pl1 {
  padding-left: 1px !important;
}

.pr1 {
  padding-right: 1px !important;
}

.pt1 {
  padding-top: 1px !important;
}

.pb1 {
  padding-bottom: 1px !important;
}

.px1 {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.py1 {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.p2 {
  padding: 2px !important;
}

.pl2 {
  padding-left: 2px !important;
}

.pr2 {
  padding-right: 2px !important;
}

.pt2 {
  padding-top: 2px !important;
}

.pb2 {
  padding-bottom: 2px !important;
}

.px2 {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.py2 {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.p3 {
  padding: 3px !important;
}

.pl3 {
  padding-left: 3px !important;
}

.pr3 {
  padding-right: 3px !important;
}

.pt3 {
  padding-top: 3px !important;
}

.pb3 {
  padding-bottom: 3px !important;
}

.px3 {
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.py3 {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.p4 {
  padding: 4px !important;
}

.pl4 {
  padding-left: 4px !important;
}

.pr4 {
  padding-right: 4px !important;
}

.pt4 {
  padding-top: 4px !important;
}

.pb4 {
  padding-bottom: 4px !important;
}

.px4 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.py4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.p5 {
  padding: 5px !important;
}

.pl5 {
  padding-left: 5px !important;
}

.pr5 {
  padding-right: 5px !important;
}

.pt5 {
  padding-top: 5px !important;
}

.pb5 {
  padding-bottom: 5px !important;
}

.px5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.py5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.p6 {
  padding: 6px !important;
}

.pl6 {
  padding-left: 6px !important;
}

.pr6 {
  padding-right: 6px !important;
}

.pt6 {
  padding-top: 6px !important;
}

.pb6 {
  padding-bottom: 6px !important;
}

.px6 {
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.py6 {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.p7 {
  padding: 7px !important;
}

.pl7 {
  padding-left: 7px !important;
}

.pr7 {
  padding-right: 7px !important;
}

.pt7 {
  padding-top: 7px !important;
}

.pb7 {
  padding-bottom: 7px !important;
}

.px7 {
  padding-left: 7px !important;
  padding-right: 7px !important;
}

.py7 {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.p8 {
  padding: 8px !important;
}

.pl8 {
  padding-left: 8px !important;
}

.pr8 {
  padding-right: 8px !important;
}

.pt8 {
  padding-top: 8px !important;
}

.pb8 {
  padding-bottom: 8px !important;
}

.px8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.py8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.p9 {
  padding: 9px !important;
}

.pl9 {
  padding-left: 9px !important;
}

.pr9 {
  padding-right: 9px !important;
}

.pt9 {
  padding-top: 9px !important;
}

.pb9 {
  padding-bottom: 9px !important;
}

.px9 {
  padding-left: 9px !important;
  padding-right: 9px !important;
}

.py9 {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}

.p10 {
  padding: 10px !important;
}

.pl10 {
  padding-left: 10px !important;
}

.pr10 {
  padding-right: 10px !important;
}

.pt10 {
  padding-top: 10px !important;
}

.pb10 {
  padding-bottom: 10px !important;
}

.px10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.py10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.p11 {
  padding: 11px !important;
}

.pl11 {
  padding-left: 11px !important;
}

.pr11 {
  padding-right: 11px !important;
}

.pt11 {
  padding-top: 11px !important;
}

.pb11 {
  padding-bottom: 11px !important;
}

.px11 {
  padding-left: 11px !important;
  padding-right: 11px !important;
}

.py11 {
  padding-top: 11px !important;
  padding-bottom: 11px !important;
}

.p12 {
  padding: 12px !important;
}

.pl12 {
  padding-left: 12px !important;
}

.pr12 {
  padding-right: 12px !important;
}

.pt12 {
  padding-top: 12px !important;
}

.pb12 {
  padding-bottom: 12px !important;
}

.px12 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.py12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.p13 {
  padding: 13px !important;
}

.pl13 {
  padding-left: 13px !important;
}

.pr13 {
  padding-right: 13px !important;
}

.pt13 {
  padding-top: 13px !important;
}

.pb13 {
  padding-bottom: 13px !important;
}

.px13 {
  padding-left: 13px !important;
  padding-right: 13px !important;
}

.py13 {
  padding-top: 13px !important;
  padding-bottom: 13px !important;
}

.p14 {
  padding: 14px !important;
}

.pl14 {
  padding-left: 14px !important;
}

.pr14 {
  padding-right: 14px !important;
}

.pt14 {
  padding-top: 14px !important;
}

.pb14 {
  padding-bottom: 14px !important;
}

.px14 {
  padding-left: 14px !important;
  padding-right: 14px !important;
}

.py14 {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}

.p15 {
  padding: 15px !important;
}

.pl15 {
  padding-left: 15px !important;
}

.pr15 {
  padding-right: 15px !important;
}

.pt15 {
  padding-top: 15px !important;
}

.pb15 {
  padding-bottom: 15px !important;
}

.px15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.py15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.p16 {
  padding: 16px !important;
}

.pl16 {
  padding-left: 16px !important;
}

.pr16 {
  padding-right: 16px !important;
}

.pt16 {
  padding-top: 16px !important;
}

.pb16 {
  padding-bottom: 16px !important;
}

.px16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.py16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.p17 {
  padding: 17px !important;
}

.pl17 {
  padding-left: 17px !important;
}

.pr17 {
  padding-right: 17px !important;
}

.pt17 {
  padding-top: 17px !important;
}

.pb17 {
  padding-bottom: 17px !important;
}

.px17 {
  padding-left: 17px !important;
  padding-right: 17px !important;
}

.py17 {
  padding-top: 17px !important;
  padding-bottom: 17px !important;
}

.p18 {
  padding: 18px !important;
}

.pl18 {
  padding-left: 18px !important;
}

.pr18 {
  padding-right: 18px !important;
}

.pt18 {
  padding-top: 18px !important;
}

.pb18 {
  padding-bottom: 18px !important;
}

.px18 {
  padding-left: 18px !important;
  padding-right: 18px !important;
}

.py18 {
  padding-top: 18px !important;
  padding-bottom: 18px !important;
}

.p19 {
  padding: 19px !important;
}

.pl19 {
  padding-left: 19px !important;
}

.pr19 {
  padding-right: 19px !important;
}

.pt19 {
  padding-top: 19px !important;
}

.pb19 {
  padding-bottom: 19px !important;
}

.px19 {
  padding-left: 19px !important;
  padding-right: 19px !important;
}

.py19 {
  padding-top: 19px !important;
  padding-bottom: 19px !important;
}

.p20 {
  padding: 20px !important;
}

.pl20 {
  padding-left: 20px !important;
}

.pr20 {
  padding-right: 20px !important;
}

.pt20 {
  padding-top: 20px !important;
}

.pb20 {
  padding-bottom: 20px !important;
}

.px20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.py20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.p21 {
  padding: 21px !important;
}

.pl21 {
  padding-left: 21px !important;
}

.pr21 {
  padding-right: 21px !important;
}

.pt21 {
  padding-top: 21px !important;
}

.pb21 {
  padding-bottom: 21px !important;
}

.px21 {
  padding-left: 21px !important;
  padding-right: 21px !important;
}

.py21 {
  padding-top: 21px !important;
  padding-bottom: 21px !important;
}

.p22 {
  padding: 22px !important;
}

.pl22 {
  padding-left: 22px !important;
}

.pr22 {
  padding-right: 22px !important;
}

.pt22 {
  padding-top: 22px !important;
}

.pb22 {
  padding-bottom: 22px !important;
}

.px22 {
  padding-left: 22px !important;
  padding-right: 22px !important;
}

.py22 {
  padding-top: 22px !important;
  padding-bottom: 22px !important;
}

.p23 {
  padding: 23px !important;
}

.pl23 {
  padding-left: 23px !important;
}

.pr23 {
  padding-right: 23px !important;
}

.pt23 {
  padding-top: 23px !important;
}

.pb23 {
  padding-bottom: 23px !important;
}

.px23 {
  padding-left: 23px !important;
  padding-right: 23px !important;
}

.py23 {
  padding-top: 23px !important;
  padding-bottom: 23px !important;
}

.p24 {
  padding: 24px !important;
}

.pl24 {
  padding-left: 24px !important;
}

.pr24 {
  padding-right: 24px !important;
}

.pt24 {
  padding-top: 24px !important;
}

.pb24 {
  padding-bottom: 24px !important;
}

.px24 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.py24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.p25 {
  padding: 25px !important;
}

.pl25 {
  padding-left: 25px !important;
}

.pr25 {
  padding-right: 25px !important;
}

.pt25 {
  padding-top: 25px !important;
}

.pb25 {
  padding-bottom: 25px !important;
}

.px25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.py25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.p26 {
  padding: 26px !important;
}

.pl26 {
  padding-left: 26px !important;
}

.pr26 {
  padding-right: 26px !important;
}

.pt26 {
  padding-top: 26px !important;
}

.pb26 {
  padding-bottom: 26px !important;
}

.px26 {
  padding-left: 26px !important;
  padding-right: 26px !important;
}

.py26 {
  padding-top: 26px !important;
  padding-bottom: 26px !important;
}

.p27 {
  padding: 27px !important;
}

.pl27 {
  padding-left: 27px !important;
}

.pr27 {
  padding-right: 27px !important;
}

.pt27 {
  padding-top: 27px !important;
}

.pb27 {
  padding-bottom: 27px !important;
}

.px27 {
  padding-left: 27px !important;
  padding-right: 27px !important;
}

.py27 {
  padding-top: 27px !important;
  padding-bottom: 27px !important;
}

.p28 {
  padding: 28px !important;
}

.pl28 {
  padding-left: 28px !important;
}

.pr28 {
  padding-right: 28px !important;
}

.pt28 {
  padding-top: 28px !important;
}

.pb28 {
  padding-bottom: 28px !important;
}

.px28 {
  padding-left: 28px !important;
  padding-right: 28px !important;
}

.py28 {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}

.p29 {
  padding: 29px !important;
}

.pl29 {
  padding-left: 29px !important;
}

.pr29 {
  padding-right: 29px !important;
}

.pt29 {
  padding-top: 29px !important;
}

.pb29 {
  padding-bottom: 29px !important;
}

.px29 {
  padding-left: 29px !important;
  padding-right: 29px !important;
}

.py29 {
  padding-top: 29px !important;
  padding-bottom: 29px !important;
}

.p30 {
  padding: 30px !important;
}

.pl30 {
  padding-left: 30px !important;
}

.pr30 {
  padding-right: 30px !important;
}

.pt30 {
  padding-top: 30px !important;
}

.pb30 {
  padding-bottom: 30px !important;
}

.px30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.py30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.p31 {
  padding: 31px !important;
}

.pl31 {
  padding-left: 31px !important;
}

.pr31 {
  padding-right: 31px !important;
}

.pt31 {
  padding-top: 31px !important;
}

.pb31 {
  padding-bottom: 31px !important;
}

.px31 {
  padding-left: 31px !important;
  padding-right: 31px !important;
}

.py31 {
  padding-top: 31px !important;
  padding-bottom: 31px !important;
}

.p32 {
  padding: 32px !important;
}

.pl32 {
  padding-left: 32px !important;
}

.pr32 {
  padding-right: 32px !important;
}

.pt32 {
  padding-top: 32px !important;
}

.pb32 {
  padding-bottom: 32px !important;
}

.px32 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.py32 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.p33 {
  padding: 33px !important;
}

.pl33 {
  padding-left: 33px !important;
}

.pr33 {
  padding-right: 33px !important;
}

.pt33 {
  padding-top: 33px !important;
}

.pb33 {
  padding-bottom: 33px !important;
}

.px33 {
  padding-left: 33px !important;
  padding-right: 33px !important;
}

.py33 {
  padding-top: 33px !important;
  padding-bottom: 33px !important;
}

.p34 {
  padding: 34px !important;
}

.pl34 {
  padding-left: 34px !important;
}

.pr34 {
  padding-right: 34px !important;
}

.pt34 {
  padding-top: 34px !important;
}

.pb34 {
  padding-bottom: 34px !important;
}

.px34 {
  padding-left: 34px !important;
  padding-right: 34px !important;
}

.py34 {
  padding-top: 34px !important;
  padding-bottom: 34px !important;
}

.p35 {
  padding: 35px !important;
}

.pl35 {
  padding-left: 35px !important;
}

.pr35 {
  padding-right: 35px !important;
}

.pt35 {
  padding-top: 35px !important;
}

.pb35 {
  padding-bottom: 35px !important;
}

.px35 {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.py35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.p36 {
  padding: 36px !important;
}

.pl36 {
  padding-left: 36px !important;
}

.pr36 {
  padding-right: 36px !important;
}

.pt36 {
  padding-top: 36px !important;
}

.pb36 {
  padding-bottom: 36px !important;
}

.px36 {
  padding-left: 36px !important;
  padding-right: 36px !important;
}

.py36 {
  padding-top: 36px !important;
  padding-bottom: 36px !important;
}

.p37 {
  padding: 37px !important;
}

.pl37 {
  padding-left: 37px !important;
}

.pr37 {
  padding-right: 37px !important;
}

.pt37 {
  padding-top: 37px !important;
}

.pb37 {
  padding-bottom: 37px !important;
}

.px37 {
  padding-left: 37px !important;
  padding-right: 37px !important;
}

.py37 {
  padding-top: 37px !important;
  padding-bottom: 37px !important;
}

.p38 {
  padding: 38px !important;
}

.pl38 {
  padding-left: 38px !important;
}

.pr38 {
  padding-right: 38px !important;
}

.pt38 {
  padding-top: 38px !important;
}

.pb38 {
  padding-bottom: 38px !important;
}

.px38 {
  padding-left: 38px !important;
  padding-right: 38px !important;
}

.py38 {
  padding-top: 38px !important;
  padding-bottom: 38px !important;
}

.p39 {
  padding: 39px !important;
}

.pl39 {
  padding-left: 39px !important;
}

.pr39 {
  padding-right: 39px !important;
}

.pt39 {
  padding-top: 39px !important;
}

.pb39 {
  padding-bottom: 39px !important;
}

.px39 {
  padding-left: 39px !important;
  padding-right: 39px !important;
}

.py39 {
  padding-top: 39px !important;
  padding-bottom: 39px !important;
}

.p40 {
  padding: 40px !important;
}

.pl40 {
  padding-left: 40px !important;
}

.pr40 {
  padding-right: 40px !important;
}

.pt40 {
  padding-top: 40px !important;
}

.pb40 {
  padding-bottom: 40px !important;
}

.px40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.py40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.p41 {
  padding: 41px !important;
}

.pl41 {
  padding-left: 41px !important;
}

.pr41 {
  padding-right: 41px !important;
}

.pt41 {
  padding-top: 41px !important;
}

.pb41 {
  padding-bottom: 41px !important;
}

.px41 {
  padding-left: 41px !important;
  padding-right: 41px !important;
}

.py41 {
  padding-top: 41px !important;
  padding-bottom: 41px !important;
}

.p42 {
  padding: 42px !important;
}

.pl42 {
  padding-left: 42px !important;
}

.pr42 {
  padding-right: 42px !important;
}

.pt42 {
  padding-top: 42px !important;
}

.pb42 {
  padding-bottom: 42px !important;
}

.px42 {
  padding-left: 42px !important;
  padding-right: 42px !important;
}

.py42 {
  padding-top: 42px !important;
  padding-bottom: 42px !important;
}

.p43 {
  padding: 43px !important;
}

.pl43 {
  padding-left: 43px !important;
}

.pr43 {
  padding-right: 43px !important;
}

.pt43 {
  padding-top: 43px !important;
}

.pb43 {
  padding-bottom: 43px !important;
}

.px43 {
  padding-left: 43px !important;
  padding-right: 43px !important;
}

.py43 {
  padding-top: 43px !important;
  padding-bottom: 43px !important;
}

.p44 {
  padding: 44px !important;
}

.pl44 {
  padding-left: 44px !important;
}

.pr44 {
  padding-right: 44px !important;
}

.pt44 {
  padding-top: 44px !important;
}

.pb44 {
  padding-bottom: 44px !important;
}

.px44 {
  padding-left: 44px !important;
  padding-right: 44px !important;
}

.py44 {
  padding-top: 44px !important;
  padding-bottom: 44px !important;
}

.p45 {
  padding: 45px !important;
}

.pl45 {
  padding-left: 45px !important;
}

.pr45 {
  padding-right: 45px !important;
}

.pt45 {
  padding-top: 45px !important;
}

.pb45 {
  padding-bottom: 45px !important;
}

.px45 {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.py45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

.p46 {
  padding: 46px !important;
}

.pl46 {
  padding-left: 46px !important;
}

.pr46 {
  padding-right: 46px !important;
}

.pt46 {
  padding-top: 46px !important;
}

.pb46 {
  padding-bottom: 46px !important;
}

.px46 {
  padding-left: 46px !important;
  padding-right: 46px !important;
}

.py46 {
  padding-top: 46px !important;
  padding-bottom: 46px !important;
}

.p47 {
  padding: 47px !important;
}

.pl47 {
  padding-left: 47px !important;
}

.pr47 {
  padding-right: 47px !important;
}

.pt47 {
  padding-top: 47px !important;
}

.pb47 {
  padding-bottom: 47px !important;
}

.px47 {
  padding-left: 47px !important;
  padding-right: 47px !important;
}

.py47 {
  padding-top: 47px !important;
  padding-bottom: 47px !important;
}

.p48 {
  padding: 48px !important;
}

.pl48 {
  padding-left: 48px !important;
}

.pr48 {
  padding-right: 48px !important;
}

.pt48 {
  padding-top: 48px !important;
}

.pb48 {
  padding-bottom: 48px !important;
}

.px48 {
  padding-left: 48px !important;
  padding-right: 48px !important;
}

.py48 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.p49 {
  padding: 49px !important;
}

.pl49 {
  padding-left: 49px !important;
}

.pr49 {
  padding-right: 49px !important;
}

.pt49 {
  padding-top: 49px !important;
}

.pb49 {
  padding-bottom: 49px !important;
}

.px49 {
  padding-left: 49px !important;
  padding-right: 49px !important;
}

.py49 {
  padding-top: 49px !important;
  padding-bottom: 49px !important;
}

.p50 {
  padding: 50px !important;
}

.pl50 {
  padding-left: 50px !important;
}

.pr50 {
  padding-right: 50px !important;
}

.pt50 {
  padding-top: 50px !important;
}

.pb50 {
  padding-bottom: 50px !important;
}

.px50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.py50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.p51 {
  padding: 51px !important;
}

.pl51 {
  padding-left: 51px !important;
}

.pr51 {
  padding-right: 51px !important;
}

.pt51 {
  padding-top: 51px !important;
}

.pb51 {
  padding-bottom: 51px !important;
}

.px51 {
  padding-left: 51px !important;
  padding-right: 51px !important;
}

.py51 {
  padding-top: 51px !important;
  padding-bottom: 51px !important;
}

.p52 {
  padding: 52px !important;
}

.pl52 {
  padding-left: 52px !important;
}

.pr52 {
  padding-right: 52px !important;
}

.pt52 {
  padding-top: 52px !important;
}

.pb52 {
  padding-bottom: 52px !important;
}

.px52 {
  padding-left: 52px !important;
  padding-right: 52px !important;
}

.py52 {
  padding-top: 52px !important;
  padding-bottom: 52px !important;
}

.p53 {
  padding: 53px !important;
}

.pl53 {
  padding-left: 53px !important;
}

.pr53 {
  padding-right: 53px !important;
}

.pt53 {
  padding-top: 53px !important;
}

.pb53 {
  padding-bottom: 53px !important;
}

.px53 {
  padding-left: 53px !important;
  padding-right: 53px !important;
}

.py53 {
  padding-top: 53px !important;
  padding-bottom: 53px !important;
}

.p54 {
  padding: 54px !important;
}

.pl54 {
  padding-left: 54px !important;
}

.pr54 {
  padding-right: 54px !important;
}

.pt54 {
  padding-top: 54px !important;
}

.pb54 {
  padding-bottom: 54px !important;
}

.px54 {
  padding-left: 54px !important;
  padding-right: 54px !important;
}

.py54 {
  padding-top: 54px !important;
  padding-bottom: 54px !important;
}

.p55 {
  padding: 55px !important;
}

.pl55 {
  padding-left: 55px !important;
}

.pr55 {
  padding-right: 55px !important;
}

.pt55 {
  padding-top: 55px !important;
}

.pb55 {
  padding-bottom: 55px !important;
}

.px55 {
  padding-left: 55px !important;
  padding-right: 55px !important;
}

.py55 {
  padding-top: 55px !important;
  padding-bottom: 55px !important;
}

.p56 {
  padding: 56px !important;
}

.pl56 {
  padding-left: 56px !important;
}

.pr56 {
  padding-right: 56px !important;
}

.pt56 {
  padding-top: 56px !important;
}

.pb56 {
  padding-bottom: 56px !important;
}

.px56 {
  padding-left: 56px !important;
  padding-right: 56px !important;
}

.py56 {
  padding-top: 56px !important;
  padding-bottom: 56px !important;
}

.p57 {
  padding: 57px !important;
}

.pl57 {
  padding-left: 57px !important;
}

.pr57 {
  padding-right: 57px !important;
}

.pt57 {
  padding-top: 57px !important;
}

.pb57 {
  padding-bottom: 57px !important;
}

.px57 {
  padding-left: 57px !important;
  padding-right: 57px !important;
}

.py57 {
  padding-top: 57px !important;
  padding-bottom: 57px !important;
}

.p58 {
  padding: 58px !important;
}

.pl58 {
  padding-left: 58px !important;
}

.pr58 {
  padding-right: 58px !important;
}

.pt58 {
  padding-top: 58px !important;
}

.pb58 {
  padding-bottom: 58px !important;
}

.px58 {
  padding-left: 58px !important;
  padding-right: 58px !important;
}

.py58 {
  padding-top: 58px !important;
  padding-bottom: 58px !important;
}

.p59 {
  padding: 59px !important;
}

.pl59 {
  padding-left: 59px !important;
}

.pr59 {
  padding-right: 59px !important;
}

.pt59 {
  padding-top: 59px !important;
}

.pb59 {
  padding-bottom: 59px !important;
}

.px59 {
  padding-left: 59px !important;
  padding-right: 59px !important;
}

.py59 {
  padding-top: 59px !important;
  padding-bottom: 59px !important;
}

.p60 {
  padding: 60px !important;
}

.pl60 {
  padding-left: 60px !important;
}

.pr60 {
  padding-right: 60px !important;
}

.pt60 {
  padding-top: 60px !important;
}

.pb60 {
  padding-bottom: 60px !important;
}

.px60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}

.py60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.p61 {
  padding: 61px !important;
}

.pl61 {
  padding-left: 61px !important;
}

.pr61 {
  padding-right: 61px !important;
}

.pt61 {
  padding-top: 61px !important;
}

.pb61 {
  padding-bottom: 61px !important;
}

.px61 {
  padding-left: 61px !important;
  padding-right: 61px !important;
}

.py61 {
  padding-top: 61px !important;
  padding-bottom: 61px !important;
}

.p62 {
  padding: 62px !important;
}

.pl62 {
  padding-left: 62px !important;
}

.pr62 {
  padding-right: 62px !important;
}

.pt62 {
  padding-top: 62px !important;
}

.pb62 {
  padding-bottom: 62px !important;
}

.px62 {
  padding-left: 62px !important;
  padding-right: 62px !important;
}

.py62 {
  padding-top: 62px !important;
  padding-bottom: 62px !important;
}

.p63 {
  padding: 63px !important;
}

.pl63 {
  padding-left: 63px !important;
}

.pr63 {
  padding-right: 63px !important;
}

.pt63 {
  padding-top: 63px !important;
}

.pb63 {
  padding-bottom: 63px !important;
}

.px63 {
  padding-left: 63px !important;
  padding-right: 63px !important;
}

.py63 {
  padding-top: 63px !important;
  padding-bottom: 63px !important;
}

.p64 {
  padding: 64px !important;
}

.pl64 {
  padding-left: 64px !important;
}

.pr64 {
  padding-right: 64px !important;
}

.pt64 {
  padding-top: 64px !important;
}

.pb64 {
  padding-bottom: 64px !important;
}

.px64 {
  padding-left: 64px !important;
  padding-right: 64px !important;
}

.py64 {
  padding-top: 64px !important;
  padding-bottom: 64px !important;
}

.p65 {
  padding: 65px !important;
}

.pl65 {
  padding-left: 65px !important;
}

.pr65 {
  padding-right: 65px !important;
}

.pt65 {
  padding-top: 65px !important;
}

.pb65 {
  padding-bottom: 65px !important;
}

.px65 {
  padding-left: 65px !important;
  padding-right: 65px !important;
}

.py65 {
  padding-top: 65px !important;
  padding-bottom: 65px !important;
}

.p66 {
  padding: 66px !important;
}

.pl66 {
  padding-left: 66px !important;
}

.pr66 {
  padding-right: 66px !important;
}

.pt66 {
  padding-top: 66px !important;
}

.pb66 {
  padding-bottom: 66px !important;
}

.px66 {
  padding-left: 66px !important;
  padding-right: 66px !important;
}

.py66 {
  padding-top: 66px !important;
  padding-bottom: 66px !important;
}

.p67 {
  padding: 67px !important;
}

.pl67 {
  padding-left: 67px !important;
}

.pr67 {
  padding-right: 67px !important;
}

.pt67 {
  padding-top: 67px !important;
}

.pb67 {
  padding-bottom: 67px !important;
}

.px67 {
  padding-left: 67px !important;
  padding-right: 67px !important;
}

.py67 {
  padding-top: 67px !important;
  padding-bottom: 67px !important;
}

.p68 {
  padding: 68px !important;
}

.pl68 {
  padding-left: 68px !important;
}

.pr68 {
  padding-right: 68px !important;
}

.pt68 {
  padding-top: 68px !important;
}

.pb68 {
  padding-bottom: 68px !important;
}

.px68 {
  padding-left: 68px !important;
  padding-right: 68px !important;
}

.py68 {
  padding-top: 68px !important;
  padding-bottom: 68px !important;
}

.p69 {
  padding: 69px !important;
}

.pl69 {
  padding-left: 69px !important;
}

.pr69 {
  padding-right: 69px !important;
}

.pt69 {
  padding-top: 69px !important;
}

.pb69 {
  padding-bottom: 69px !important;
}

.px69 {
  padding-left: 69px !important;
  padding-right: 69px !important;
}

.py69 {
  padding-top: 69px !important;
  padding-bottom: 69px !important;
}

.p70 {
  padding: 70px !important;
}

.pl70 {
  padding-left: 70px !important;
}

.pr70 {
  padding-right: 70px !important;
}

.pt70 {
  padding-top: 70px !important;
}

.pb70 {
  padding-bottom: 70px !important;
}

.px70 {
  padding-left: 70px !important;
  padding-right: 70px !important;
}

.py70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.p71 {
  padding: 71px !important;
}

.pl71 {
  padding-left: 71px !important;
}

.pr71 {
  padding-right: 71px !important;
}

.pt71 {
  padding-top: 71px !important;
}

.pb71 {
  padding-bottom: 71px !important;
}

.px71 {
  padding-left: 71px !important;
  padding-right: 71px !important;
}

.py71 {
  padding-top: 71px !important;
  padding-bottom: 71px !important;
}

.p72 {
  padding: 72px !important;
}

.pl72 {
  padding-left: 72px !important;
}

.pr72 {
  padding-right: 72px !important;
}

.pt72 {
  padding-top: 72px !important;
}

.pb72 {
  padding-bottom: 72px !important;
}

.px72 {
  padding-left: 72px !important;
  padding-right: 72px !important;
}

.py72 {
  padding-top: 72px !important;
  padding-bottom: 72px !important;
}

.p73 {
  padding: 73px !important;
}

.pl73 {
  padding-left: 73px !important;
}

.pr73 {
  padding-right: 73px !important;
}

.pt73 {
  padding-top: 73px !important;
}

.pb73 {
  padding-bottom: 73px !important;
}

.px73 {
  padding-left: 73px !important;
  padding-right: 73px !important;
}

.py73 {
  padding-top: 73px !important;
  padding-bottom: 73px !important;
}

.p74 {
  padding: 74px !important;
}

.pl74 {
  padding-left: 74px !important;
}

.pr74 {
  padding-right: 74px !important;
}

.pt74 {
  padding-top: 74px !important;
}

.pb74 {
  padding-bottom: 74px !important;
}

.px74 {
  padding-left: 74px !important;
  padding-right: 74px !important;
}

.py74 {
  padding-top: 74px !important;
  padding-bottom: 74px !important;
}

.p75 {
  padding: 75px !important;
}

.pl75 {
  padding-left: 75px !important;
}

.pr75 {
  padding-right: 75px !important;
}

.pt75 {
  padding-top: 75px !important;
}

.pb75 {
  padding-bottom: 75px !important;
}

.px75 {
  padding-left: 75px !important;
  padding-right: 75px !important;
}

.py75 {
  padding-top: 75px !important;
  padding-bottom: 75px !important;
}

.p76 {
  padding: 76px !important;
}

.pl76 {
  padding-left: 76px !important;
}

.pr76 {
  padding-right: 76px !important;
}

.pt76 {
  padding-top: 76px !important;
}

.pb76 {
  padding-bottom: 76px !important;
}

.px76 {
  padding-left: 76px !important;
  padding-right: 76px !important;
}

.py76 {
  padding-top: 76px !important;
  padding-bottom: 76px !important;
}

.p77 {
  padding: 77px !important;
}

.pl77 {
  padding-left: 77px !important;
}

.pr77 {
  padding-right: 77px !important;
}

.pt77 {
  padding-top: 77px !important;
}

.pb77 {
  padding-bottom: 77px !important;
}

.px77 {
  padding-left: 77px !important;
  padding-right: 77px !important;
}

.py77 {
  padding-top: 77px !important;
  padding-bottom: 77px !important;
}

.p78 {
  padding: 78px !important;
}

.pl78 {
  padding-left: 78px !important;
}

.pr78 {
  padding-right: 78px !important;
}

.pt78 {
  padding-top: 78px !important;
}

.pb78 {
  padding-bottom: 78px !important;
}

.px78 {
  padding-left: 78px !important;
  padding-right: 78px !important;
}

.py78 {
  padding-top: 78px !important;
  padding-bottom: 78px !important;
}

.p79 {
  padding: 79px !important;
}

.pl79 {
  padding-left: 79px !important;
}

.pr79 {
  padding-right: 79px !important;
}

.pt79 {
  padding-top: 79px !important;
}

.pb79 {
  padding-bottom: 79px !important;
}

.px79 {
  padding-left: 79px !important;
  padding-right: 79px !important;
}

.py79 {
  padding-top: 79px !important;
  padding-bottom: 79px !important;
}

.p80 {
  padding: 80px !important;
}

.pl80 {
  padding-left: 80px !important;
}

.pr80 {
  padding-right: 80px !important;
}

.pt80 {
  padding-top: 80px !important;
}

.pb80 {
  padding-bottom: 80px !important;
}

.px80 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.py80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.p81 {
  padding: 81px !important;
}

.pl81 {
  padding-left: 81px !important;
}

.pr81 {
  padding-right: 81px !important;
}

.pt81 {
  padding-top: 81px !important;
}

.pb81 {
  padding-bottom: 81px !important;
}

.px81 {
  padding-left: 81px !important;
  padding-right: 81px !important;
}

.py81 {
  padding-top: 81px !important;
  padding-bottom: 81px !important;
}

.p82 {
  padding: 82px !important;
}

.pl82 {
  padding-left: 82px !important;
}

.pr82 {
  padding-right: 82px !important;
}

.pt82 {
  padding-top: 82px !important;
}

.pb82 {
  padding-bottom: 82px !important;
}

.px82 {
  padding-left: 82px !important;
  padding-right: 82px !important;
}

.py82 {
  padding-top: 82px !important;
  padding-bottom: 82px !important;
}

.p83 {
  padding: 83px !important;
}

.pl83 {
  padding-left: 83px !important;
}

.pr83 {
  padding-right: 83px !important;
}

.pt83 {
  padding-top: 83px !important;
}

.pb83 {
  padding-bottom: 83px !important;
}

.px83 {
  padding-left: 83px !important;
  padding-right: 83px !important;
}

.py83 {
  padding-top: 83px !important;
  padding-bottom: 83px !important;
}

.p84 {
  padding: 84px !important;
}

.pl84 {
  padding-left: 84px !important;
}

.pr84 {
  padding-right: 84px !important;
}

.pt84 {
  padding-top: 84px !important;
}

.pb84 {
  padding-bottom: 84px !important;
}

.px84 {
  padding-left: 84px !important;
  padding-right: 84px !important;
}

.py84 {
  padding-top: 84px !important;
  padding-bottom: 84px !important;
}

.p85 {
  padding: 85px !important;
}

.pl85 {
  padding-left: 85px !important;
}

.pr85 {
  padding-right: 85px !important;
}

.pt85 {
  padding-top: 85px !important;
}

.pb85 {
  padding-bottom: 85px !important;
}

.px85 {
  padding-left: 85px !important;
  padding-right: 85px !important;
}

.py85 {
  padding-top: 85px !important;
  padding-bottom: 85px !important;
}

.p86 {
  padding: 86px !important;
}

.pl86 {
  padding-left: 86px !important;
}

.pr86 {
  padding-right: 86px !important;
}

.pt86 {
  padding-top: 86px !important;
}

.pb86 {
  padding-bottom: 86px !important;
}

.px86 {
  padding-left: 86px !important;
  padding-right: 86px !important;
}

.py86 {
  padding-top: 86px !important;
  padding-bottom: 86px !important;
}

.p87 {
  padding: 87px !important;
}

.pl87 {
  padding-left: 87px !important;
}

.pr87 {
  padding-right: 87px !important;
}

.pt87 {
  padding-top: 87px !important;
}

.pb87 {
  padding-bottom: 87px !important;
}

.px87 {
  padding-left: 87px !important;
  padding-right: 87px !important;
}

.py87 {
  padding-top: 87px !important;
  padding-bottom: 87px !important;
}

.p88 {
  padding: 88px !important;
}

.pl88 {
  padding-left: 88px !important;
}

.pr88 {
  padding-right: 88px !important;
}

.pt88 {
  padding-top: 88px !important;
}

.pb88 {
  padding-bottom: 88px !important;
}

.px88 {
  padding-left: 88px !important;
  padding-right: 88px !important;
}

.py88 {
  padding-top: 88px !important;
  padding-bottom: 88px !important;
}

.p89 {
  padding: 89px !important;
}

.pl89 {
  padding-left: 89px !important;
}

.pr89 {
  padding-right: 89px !important;
}

.pt89 {
  padding-top: 89px !important;
}

.pb89 {
  padding-bottom: 89px !important;
}

.px89 {
  padding-left: 89px !important;
  padding-right: 89px !important;
}

.py89 {
  padding-top: 89px !important;
  padding-bottom: 89px !important;
}

.p90 {
  padding: 90px !important;
}

.pl90 {
  padding-left: 90px !important;
}

.pr90 {
  padding-right: 90px !important;
}

.pt90 {
  padding-top: 90px !important;
}

.pb90 {
  padding-bottom: 90px !important;
}

.px90 {
  padding-left: 90px !important;
  padding-right: 90px !important;
}

.py90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

.p91 {
  padding: 91px !important;
}

.pl91 {
  padding-left: 91px !important;
}

.pr91 {
  padding-right: 91px !important;
}

.pt91 {
  padding-top: 91px !important;
}

.pb91 {
  padding-bottom: 91px !important;
}

.px91 {
  padding-left: 91px !important;
  padding-right: 91px !important;
}

.py91 {
  padding-top: 91px !important;
  padding-bottom: 91px !important;
}

.p92 {
  padding: 92px !important;
}

.pl92 {
  padding-left: 92px !important;
}

.pr92 {
  padding-right: 92px !important;
}

.pt92 {
  padding-top: 92px !important;
}

.pb92 {
  padding-bottom: 92px !important;
}

.px92 {
  padding-left: 92px !important;
  padding-right: 92px !important;
}

.py92 {
  padding-top: 92px !important;
  padding-bottom: 92px !important;
}

.p93 {
  padding: 93px !important;
}

.pl93 {
  padding-left: 93px !important;
}

.pr93 {
  padding-right: 93px !important;
}

.pt93 {
  padding-top: 93px !important;
}

.pb93 {
  padding-bottom: 93px !important;
}

.px93 {
  padding-left: 93px !important;
  padding-right: 93px !important;
}

.py93 {
  padding-top: 93px !important;
  padding-bottom: 93px !important;
}

.p94 {
  padding: 94px !important;
}

.pl94 {
  padding-left: 94px !important;
}

.pr94 {
  padding-right: 94px !important;
}

.pt94 {
  padding-top: 94px !important;
}

.pb94 {
  padding-bottom: 94px !important;
}

.px94 {
  padding-left: 94px !important;
  padding-right: 94px !important;
}

.py94 {
  padding-top: 94px !important;
  padding-bottom: 94px !important;
}

.p95 {
  padding: 95px !important;
}

.pl95 {
  padding-left: 95px !important;
}

.pr95 {
  padding-right: 95px !important;
}

.pt95 {
  padding-top: 95px !important;
}

.pb95 {
  padding-bottom: 95px !important;
}

.px95 {
  padding-left: 95px !important;
  padding-right: 95px !important;
}

.py95 {
  padding-top: 95px !important;
  padding-bottom: 95px !important;
}

.p96 {
  padding: 96px !important;
}

.pl96 {
  padding-left: 96px !important;
}

.pr96 {
  padding-right: 96px !important;
}

.pt96 {
  padding-top: 96px !important;
}

.pb96 {
  padding-bottom: 96px !important;
}

.px96 {
  padding-left: 96px !important;
  padding-right: 96px !important;
}

.py96 {
  padding-top: 96px !important;
  padding-bottom: 96px !important;
}

.p97 {
  padding: 97px !important;
}

.pl97 {
  padding-left: 97px !important;
}

.pr97 {
  padding-right: 97px !important;
}

.pt97 {
  padding-top: 97px !important;
}

.pb97 {
  padding-bottom: 97px !important;
}

.px97 {
  padding-left: 97px !important;
  padding-right: 97px !important;
}

.py97 {
  padding-top: 97px !important;
  padding-bottom: 97px !important;
}

.p98 {
  padding: 98px !important;
}

.pl98 {
  padding-left: 98px !important;
}

.pr98 {
  padding-right: 98px !important;
}

.pt98 {
  padding-top: 98px !important;
}

.pb98 {
  padding-bottom: 98px !important;
}

.px98 {
  padding-left: 98px !important;
  padding-right: 98px !important;
}

.py98 {
  padding-top: 98px !important;
  padding-bottom: 98px !important;
}

.p99 {
  padding: 99px !important;
}

.pl99 {
  padding-left: 99px !important;
}

.pr99 {
  padding-right: 99px !important;
}

.pt99 {
  padding-top: 99px !important;
}

.pb99 {
  padding-bottom: 99px !important;
}

.px99 {
  padding-left: 99px !important;
  padding-right: 99px !important;
}

.py99 {
  padding-top: 99px !important;
  padding-bottom: 99px !important;
}

.p100 {
  padding: 100px !important;
}

.pl100 {
  padding-left: 100px !important;
}

.pr100 {
  padding-right: 100px !important;
}

.pt100 {
  padding-top: 100px !important;
}

.pb100 {
  padding-bottom: 100px !important;
}

.px100 {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

.py100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

button {
  font-size: 16px !important;
}

.mat-progress-bar {
  position: absolute !important;
  z-index: 2;
}

.mat-form-field-wrapper {
  width: 100%;
}

.mat-list-icon.mat-icon {
  padding: 8px !important;
  box-sizing: content-box !important;
}

.mat-icon {
  font-size: 20px !important;
}

.mat-form-field-prefix {
  padding: 8px 10px;
  margin-right: 16px;
}

mat-form-field.date_form-field {
  box-sizing: border-box;
}
mat-form-field.date_form-field .mat-form-field-infix {
  display: flex;
}
mat-form-field.date_form-field .mat-form-field-infix mat-datepicker-toggle {
  position: absolute;
  right: 0;
  bottom: 0.5em;
}

mat-toolbar-row .mat-icon :not(.mat-form-field-suffix .mat-icon) {
  padding: 8px !important;
  box-sizing: content-box !important;
}

.mat-raised-button mat-icon {
  font-size: 1.33333em;
  margin-right: 8px;
}

.mat-fab .mat-icon,
.mat-mini-fab .mat-icon {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.mat-horizontal-stepper-header-container {
  display: none !important;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.login {
  background: url("/../../assets/img/background.jpg") no-repeat center center;
  background-size: cover;
}
.login .login-card {
  max-height: calc(100vh - 56px);
  overflow: hidden;
}
.login .login-card .mat-card-header {
  margin-bottom: 24px;
}
.login .login-card .mat-card-header .version {
  opacity: 0.7;
}
.login .login-card .mat-card-header .mat-card-header-text {
  width: 100%;
}
.login .login-card .mat-card-header .mat-card-header-text .brand {
  height: 25vh;
  max-height: 9rem;
  background: url("/../../assets/img/logo.png") no-repeat center center;
  background-size: contain;
  margin-bottom: 15px;
}
.login .login-card .mat-card-header .mat-card-header-text .description {
  font-size: 0.7rem;
  text-align: center;
}
.login .login-card .mat-horizontal-content-container {
  padding: 0 !important;
}
.login .login-card .mat-horizontal-content-container .mat-card-content {
  padding: 0 16px 16px !important;
  margin-bottom: 0 !important;
}
.login .login-card .mat-horizontal-content-container .mat-card-content h3 {
  margin-bottom: 32px;
}
.login .login-card .mat-horizontal-content-container .mat-card-content .mat-card-footer {
  margin: 16px 0 0 0 !important;
}
.login .login-card .mat-progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
}

.app-page {
  height: 100vh;
  width: 100vw;
  background: #e0e0e0;
  overflow: hidden;
  position: relative;
}
.app-page .modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  animation: fade-in 0.2s ease;
}
.app-page .modal .mat-card {
  border-radius: 4px;
  padding: 0 16px;
}
.app-page .modal .mat-card .header-primary {
  padding: 0 !important;
}
.app-page .modal .mat-card .modal-body {
  padding: 0 16px;
}
.app-page .modal .mat-card .modal-body .edition {
  min-width: 15vw;
  margin-left: 16px;
}
.app-page .modal .mat-card .modal-body .edition .edition-content {
  padding: 16px 0;
}
.app-page .modal .mat-card .modal-body .table-content {
  min-width: 50vw;
  margin-right: 16px;
  max-height: 80vh;
}
.app-page .modal .mat-card .modal-body .ref-content {
  margin-right: 16px;
  max-height: 80vh;
}
.app-page .modal .mat-card .modal-footer {
  padding: 16px 0;
}
.app-page .modal .wizard-add {
  min-width: 25vw;
  min-height: 15vh;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}
.app-page .modal .wizard-add .load-window {
  width: 100%;
  height: 100%;
  top: 0;
}
.app-page .modal .wizard-add .add-menu {
  background: #fff;
}
.app-page .modal .wizard-add .mat-horizontal-content-container {
  padding: 0;
}
.app-page .modal .wizard-add mat-toolbar .mat-avatar {
  font-size: 16px;
  width: calc(100% - 40px - 16px) !important;
  min-width: unset !important;
}
.app-page .modal .wizard-add mat-toolbar .mat-avatar .toolbar-line {
  width: 100%;
}
.app-page .modal .wizard-add mat-toolbar .mat-avatar .toolbar-line mat-icon {
  box-sizing: content-box !important;
  padding: 8px !important;
  min-width: 24px !important;
}
.app-page .modal .wizard-add mat-toolbar .mat-avatar .toolbar-line .title {
  width: calc(100% - 40px - 16px - 16px);
}
.app-page .modal .wizard-add mat-toolbar .mat-avatar .toolbar-line .title h3 {
  font-size: 20px;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.app-page .modal .wizard-add mat-toolbar .mat-avatar .toolbar-line .title h5 {
  font-size: 12px;
  line-height: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.app-page .modal .wizard-add .wizard-body {
  padding: 32px 32px 0;
  max-height: calc(90vh - 64px - 68px);
  overflow: auto;
}
.app-page .modal .wizard-add .wizard-body app-upload-form .dropzone {
  padding: 32px !important;
}
.app-page .modal .wizard-add .wizard-body form {
  padding: 0 !important;
}
.app-page .modal .wizard-add .wizard.footer {
  padding: 16px 32px;
}
.app-page .app-container {
  height: 100vh !important;
  min-width: calc(100vw - 384px);
  position: relative;
  background: #fff;
}
.app-page .app-container .load-window {
  position: absolute;
  z-index: 101;
  background: white;
  top: 128px;
  width: 100%;
  height: calc(100% - 128px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.app-page .app-container .main-toolbar {
  min-height: 96px;
  height: 96px;
}
.app-page .app-container .main-toolbar .mat-avatar {
  font-size: 16px;
  width: calc(100% - 40px - 16px) !important;
  min-width: unset !important;
}
.app-page .app-container .main-toolbar .mat-avatar .toolbar-line {
  width: 100%;
}
.app-page .app-container .main-toolbar .mat-avatar .toolbar-line mat-icon {
  box-sizing: content-box !important;
  padding: 8px !important;
  min-width: 24px !important;
}
.app-page .app-container .main-toolbar .mat-avatar .toolbar-line .title {
  width: calc(100% - 40px - 16px - 16px);
}
.app-page .app-container .main-toolbar .mat-avatar .toolbar-line .title h3 {
  font-size: 20px;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.app-page .app-container .main-toolbar .mat-avatar .toolbar-line .title h5 {
  font-size: 12px;
  line-height: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.app-page .app-container .main-toolbar .mat-avatar .mat-stroked-button {
  min-width: unset !important;
}
.app-page .app-container .main-toolbar .mat-avatar .mat-stroked-button .mat-icon {
  margin-right: 8px !important;
  box-sizing: content-box !important;
}
.app-page .app-container .main-toolbar .mat-raised-button .mat-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.app-page .app-container .main-toolbar .mat-raised-button .mat-button-wrapper .mat-icon {
  box-sizing: content-box !important;
  margin-right: 8px;
}
.app-page .app-container .main-toolbar .mat-raised-button .mat-button-wrapper .mat-icon + span {
  padding-right: 8px;
}
.app-page .app-container .main-container {
  background: #fff;
  height: calc(100% - 96px);
}
.app-page .app-container .main-container .list-container {
  position: relative;
}
.app-page .app-container .side-container.darker {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-table {
  height: 100%;
  overflow: auto;
}
.mat-table.padded {
  height: calc(100% - 64px);
}
.mat-table.padded .w-12 {
  padding: 0 16px;
}
.mat-table.padded .w-12 .center {
  text-align: center;
}
.mat-table .sticky {
  top: 0px;
  position: sticky;
  z-index: 10;
  align-items: center;
}
.mat-table .icon {
  height: 100%;
  width: 72px;
  min-width: 72px;
}
.mat-table .icon .mat-icon {
  padding: 8px !important;
  box-sizing: content-box !important;
}
.mat-table .icon.bulb button {
  color: #ffeb3b;
}
.mat-table .w-12 {
  height: 100%;
  width: 100%;
  min-width: 100%;
}
.mat-table .w-12 .sorteable {
  cursor: pointer;
}
.mat-table .w-12 .sorteable .sort-icon .mat-icon {
  font-size: 15px !important;
}
.icon + .mat-table .w-12 {
  height: 100%;
  width: calc(100% - 72px);
  min-width: calc(100% - 72px);
}
.mat-table .w-12 .w-1 {
  height: 100%;
  width: 72px;
  min-width: 72px;
}
.mat-table .w-12 .w-1 .mat-icon {
  padding: 8px !important;
  box-sizing: content-box !important;
}
.mat-table .w-12 .w-1.bulb button {
  color: #ffeb3b;
}
.mat-table .w-12 .w-1-5 {
  height: 100%;
  width: 12.5%;
  min-width: 12.5%;
}
.mat-table .w-12 .w-2 {
  height: 100%;
  width: 16.6666666667%;
  min-width: 16.6666666667%;
}
.mat-table .w-12 .w-3 {
  height: 100%;
  width: 25%;
  min-width: 25%;
}
.mat-table .w-12 .w-4 {
  height: 100%;
  width: 33.3333333333%;
  min-width: 33.3333333333%;
}
.mat-table .w-12 .w-5 {
  height: 100%;
  width: 41.6666666667%;
  min-width: 41.6666666667%;
}
.mat-table .w-12 .w-6 {
  height: 100%;
  width: 50%;
  min-width: 50%;
}
.mat-table .mat-header-row {
  min-height: 64px;
  width: 100%;
  background: #fff;
  z-index: 100 !important;
}
.mat-table .mat-row {
  height: 56px;
  width: 100%;
  position: relative;
  cursor: default;
  transition: background 0.2s ease;
}
.mat-table .mat-row .floating-action {
  position: absolute;
  right: 16px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease;
  background: #eeeeee;
  z-index: 1;
}
.mat-table .mat-row:hover {
  background: #eeeeee !important;
  transition: all 0.2s ease;
  transition-delay: 0.05s;
}
.mat-table .mat-row:hover .floating-action {
  opacity: 1;
  pointer-events: all;
  transition: opacity 0.2s ease;
}
.mat-table .mat-row:nth-child(odd) {
  background: #f5f5f5;
  transition: all 0.2s ease;
  transition-delay: 0.05s;
}
.mat-table .mat-row:nth-child(odd) .floating-action {
  background: #f5f5f5;
}
.mat-table .mat-row:focus, .mat-table .mat-row:focus:hover, .mat-table .mat-row:focus:hover .sticky-actions {
  outline: none;
}
.mat-table .mat-row.active {
  background: #90caf9 !important;
  transition: all 0.2s ease;
  transition-delay: 0.05s;
}
.mat-table .mat-row.active .floating-action {
  background: #90caf9;
  transition: all 0.2s ease;
}
.mat-table .mat-row.active .w-12 div {
  color: #fff !important;
}
.mat-table .mat-row.active:hover, .mat-table .mat-row.active:hover .sticky-actions {
  background: #90caf9 !important;
  transition: all 0.2s ease;
  transition-delay: 0.05s;
}
.mat-table .mat-row.disabled div,
.mat-table .mat-row.disabled .bulb button {
  color: #cfd8dc !important;
}
.mat-table .mat-row.disabled .floating-action {
  color: #cfd8dc !important;
}

.certificates_option.w-12 {
  width: 100%;
  min-width: 100%;
  flex-wrap: wrap;
}
.certificates_option.w-12 .w-6 {
  width: 50%;
  min-width: min-content;
  flex-grow: 1;
}

.sticky-header {
  position: sticky;
  top: 0;
  bottom: 0;
  background: #fff;
  z-index: 5;
  box-sizing: content-box;
}
.sticky-header h3.mat-subheader {
  height: 64px !important;
}

.header-primary {
  height: 64px !important;
}

.mat-list-item {
  box-sizing: border-box;
}

app-documentation-type-detail .mat-expansion-panel-body {
  padding: 0 0 16px !important;
  height: min-content;
}
app-documentation-type-detail .mat-expansion-panel-content {
  overflow-y: auto;
  overflow-y: overlay;
}

[fxlayoutgap="16px"] mat-form-field.w1-4 {
  width: calc(25% - 12px);
}

[fxlayoutgap="16px"] mat-form-field.w1-2 {
  width: calc(50% - 8px);
}

[fxlayoutgap="8px"] mat-form-field.w1-4 {
  width: calc(25% - 6px);
}

[fxlayoutgap="8px"] mat-form-field.w1-2 {
  width: calc(50% - 4px);
}

.list-container {
  position: relative;
}

.content {
  height: 100%;
}

.scrollable {
  overflow-y: auto;
  overflow-y: overlay;
  height: 100%;
}

app-upload-form {
  display: flex;
  width: 100%;
  align-items: stretch;
  flex-grow: 2;
  justify-content: center;
  max-height: calc(100% - 64px - 64px);
  box-sizing: border-box;
}

.catcher {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.15);
  transition: left 0.1s ease-out;
  left: 100%;
  z-index: 5000;
}

.catcher.on {
  left: 0;
  transition: left 0.2s ease-in;
}

.title-clickeable {
  cursor: pointer;
}

.list-title {
  color: rgba(0, 0, 0, 0.54);
}

.list-title span {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}

.primary-text {
  color: #4051b5 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.primary-text.big {
  font-size: 18px !important;
}

.accent-text {
  color: #ff4081 !important;
}

.dropzone button.mat-stroked-button {
  background: #fff;
}

.dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed #666;
  border-radius: 5px;
  height: unset !important;
  margin: 0 !important;
  background: #e8eaf6 !important;
  width: 100%;
}
.dropzone.hovering {
  border: 2px solid #666;
  color: #bfbfbf !important;
}

.dropzone h3,
.dropzone h6 {
  margin: 0;
}

.dropzone .file {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file-upload-list {
  padding: 0;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 16px;
}
.file-upload-list mat-list-item {
  border-radius: 50px;
  margin-bottom: 8px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  overflow: hidden;
  background: #fff;
  transition: background 0.2s ease;
}
.file-upload-list mat-list-item .mat-list-text {
  width: calc(100% - 16px - 36px) !important;
}
.file-upload-list mat-list-item .sliding-actions {
  transition: all 0.2s ease;
  position: absolute;
  right: -40px;
}
.file-upload-list mat-list-item:hover {
  background: #fafafa;
}
.file-upload-list mat-list-item:hover .mat-list-text {
  width: calc(100% - 16px - 32px - 40px) !important;
}
.file-upload-list mat-list-item:hover .sliding-actions {
  right: 16px;
  transition: all 0.2s ease;
}

.mat-radio-label-content {
  font-size: 14px !important;
}

.mat-slide-toggle-content {
  font-size: 12px !important;
}

span.mat-checkbox-label {
  font-size: 12px !important;
}

mat-bottom-sheet-container {
  max-width: 40vw !important;
}

.btn-label {
  text-align: left;
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
}

.btn-description {
  text-align: left;
  font-size: 14px;
  line-height: 18px;
}

.list-favourite {
  position: relative;
}

.list-favourite .header-primary {
  margin-bottom: 72px !important;
}

.list-favourite .item-action.favourite {
  position: absolute;
  top: 64px;
}

.list-favourite .item-action.favourite .mat-line {
  color: #ff4081 !important;
}

.list-favourite .item-action {
  cursor: pointer;
}

.list-favourite .item-action button {
  visibility: hidden;
}

.list-favourite .item-action:hover {
  background: rgba(0, 0, 0, 0.04);
}

.list-favourite .item-action:hover button {
  visibility: visible;
}

.disabled {
  color: rgba(0, 0, 0, 0.24);
}

b.required_candidate.ng-star-inserted {
  position: absolute;
  top: 0;
  right: 0;
}

.metadata_item-candidate_status {
  position: relative;
}

.logo-section {
  background: #e8eaf6 !important;
}
.logo-section h3 {
  background: #e8eaf6 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.logo-section .logo-container {
  background: #e8eaf6 !important;
  height: 200px;
  width: 100%;
  box-sizing: border-box;
}
.logo-section img.logo-preview {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.documentation-type-container {
  width: 100% !important;
  min-width: 100% !important;
}

.documentation-type-details-container {
  width: 100% !important;
  min-width: 100% !important;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.document-detail {
  height: 100%;
  position: relative;
}
.document-detail .title {
  width: 100%;
  height: 64px !important;
  box-sizing: border-box;
}
.document-detail .detail-panel {
  height: calc(100% - 65px);
}
.document-detail .detail-panel .resume {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  display: flex;
  flex: 1;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
}
.document-detail .detail-panel .resume .view {
  height: calc(100% - 1px);
  width: 100%;
}
.document-detail .detail-panel .resume .view .title {
  width: 100%;
  height: 64px !important;
  box-sizing: border-box;
}
.document-detail .detail-panel .resume .view mat-list {
  height: calc(100% - 64px);
  width: 100%;
  overflow-y: auto !important;
  overflow-y: overlay !important;
  box-sizing: border-box;
}
.document-detail .detail-panel .resume .view mat-list .mat-subheader:first-child {
  margin-top: 0 !important;
}
.document-detail .detail-panel .resume .view mat-list .mat-line {
  white-space: normal !important;
  overflow: unset !important;
  text-overflow: unset !important;
}
.document-detail .detail-panel .edit {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  background: #fff;
  top: 0;
}
.document-detail .detail-panel .edit .title {
  width: 100%;
  height: 64px !important;
  box-sizing: border-box;
}
.document-detail .detail-panel .edit form {
  height: calc(100% - 64px);
  width: 100%;
  overflow-y: auto !important;
  overflow-y: overlay !important;
}
.document-detail .detail-panel .edit form .form-content {
  width: 100%;
}
.document-detail .detail-panel .edit form .form-footer {
  width: 100%;
}
.document-detail .detail-panel mat-action-list {
  padding-top: 0 !important;
  width: 100%;
}
.document-detail .detail-panel mat-action-list mat-list-item {
  height: 48px !important;
}
.document-detail .detail-panel mat-action-list mat-list-item:hover {
  background: rgba(0, 0, 0, 0.12);
}
.document-detail .option-panel {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 2;
}
.document-detail .option-panel .view {
  height: 100%;
  width: 100%;
}
.document-detail .option-panel .view .title {
  width: 100%;
  height: 64px !important;
  box-sizing: border-box;
}
.document-detail .option-panel .view mat-list {
  height: calc(100% - 64px);
  width: 100%;
  overflow-y: auto !important;
  overflow-y: overlay !important;
  box-sizing: border-box;
}
.document-detail .option-panel .view mat-list .mat-subheader:first-child {
  margin-top: 0 !important;
}
.document-detail .option-panel .view mat-list .mat-line {
  white-space: normal !important;
  overflow: unset !important;
  text-overflow: unset !important;
}
.document-detail .option-panel .edit {
  height: 100%;
  width: 100%;
}
.document-detail .option-panel .edit .title {
  width: 100%;
  height: 64px !important;
  box-sizing: border-box;
}
.document-detail .option-panel .edit form {
  height: calc(100% - 64px);
  width: 100%;
  overflow-y: auto !important;
  overflow-y: overlay !important;
}
.document-detail .option-panel .edit form .form-content {
  width: 100%;
}
.document-detail .option-panel .edit form .form-footer {
  width: 100%;
}

app-validate-paycheck .verification-response * {
  margin: 0;
}
app-validate-paycheck .verification-response h3 {
  color: #607d8b;
  padding-bottom: 16px;
}
app-validate-paycheck .verification-list {
  background: #009688;
  padding: 16px;
  border-radius: 4px;
  color: #fff;
  margin-bottom: 16p;
}
app-validate-paycheck .verification-list * {
  margin: 0;
}

.version-config {
  height: 48px;
  display: flex;
  align-items: center;
  color: #fff;
  position: absolute;
  right: 16px;
  bottom: 0;
}
.version-config b {
  margin-left: 8px;
}
.version-config button {
  margin-left: 16px;
}

app-scheduled-processes-admin .list-container {
  height: 100%;
}
app-scheduled-processes-admin .list-container mat-accordion {
  height: 100%;
  display: block;
}
app-scheduled-processes-admin .list-container mat-accordion mat-icon {
  box-sizing: content-box !important;
  padding: 8px !important;
  min-width: 24px !important;
}
app-scheduled-processes-admin .list-container mat-accordion mat-expansion-panel {
  margin: 0 !important;
  box-shadow: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0 !important;
}
app-scheduled-processes-admin .list-container mat-accordion mat-expansion-panel .mat-expansion-panel-header {
  padding: 0 16px;
}
app-scheduled-processes-admin .list-container mat-accordion mat-expansion-panel .mat-expansion-panel-header[aria-disabled=true] * {
  color: rgba(0, 0, 0, 0.26) !important;
}
app-scheduled-processes-admin .list-container mat-accordion mat-expansion-panel .mat-expansion-panel-header .mat-content {
  align-items: center;
}
app-scheduled-processes-admin .list-container mat-accordion mat-expansion-panel .mat-expansion-panel-header .mat-content .actions button {
  min-width: 200px;
  height: 36px;
}
app-scheduled-processes-admin .list-container mat-accordion mat-expansion-panel .mat-expansion-panel-header .mat-content .actions button mat-icon {
  padding: 0 !important;
  margin-right: 8px !important;
}
app-scheduled-processes-admin .list-container mat-accordion mat-expansion-panel.mat-expanded {
  height: calc(100% - 98px);
}
app-scheduled-processes-admin .list-container mat-accordion mat-expansion-panel.mat-expanded .mat-expansion-panel-header {
  z-index: 1;
  background: #fff;
  position: relative;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
app-scheduled-processes-admin .list-container mat-accordion mat-expansion-panel.mat-expanded .mat-expansion-panel-content {
  height: calc(100% - 63px);
}
app-scheduled-processes-admin .list-container mat-accordion mat-expansion-panel.mat-expanded .mat-expansion-panel-content .mat-expansion-panel-body {
  height: 100%;
  overflow-y: auto;
  overflow-y: overlay;
  padding: 0 !important;
}
app-scheduled-processes-admin .list-container mat-accordion mat-accordion {
  height: unset !important;
  display: unset !important;
}
app-scheduled-processes-admin .list-container mat-accordion mat-accordion .mat-header-cell {
  padding: 0 8px;
}
app-scheduled-processes-admin .list-container mat-accordion mat-accordion .mat-header-cell span {
  line-height: 1;
}
app-scheduled-processes-admin .list-container mat-accordion mat-accordion .mat-cell {
  padding: 8px;
}
app-scheduled-processes-admin .list-container mat-accordion mat-accordion .mat-cell span {
  line-height: 1;
}
app-scheduled-processes-admin .list-container mat-accordion mat-accordion * {
  box-shadow: none !important;
}
app-scheduled-processes-admin .list-container mat-accordion mat-accordion .mat-table.padded {
  overflow-x: hidden;
}
app-scheduled-processes-admin .list-container mat-accordion mat-accordion mat-expansion-panel {
  border-bottom: 0;
}
app-scheduled-processes-admin .list-container mat-accordion mat-accordion mat-expansion-panel.mat-expanded {
  height: unset !important;
}
app-scheduled-processes-admin .list-container mat-accordion mat-accordion mat-expansion-panel.mat-expanded .mat-expansion-panel-header {
  z-index: unset !important;
  background: unset !important;
  position: unset !important;
  box-shadow: unset !important;
}
app-scheduled-processes-admin .list-container mat-accordion mat-accordion mat-expansion-panel.mat-expanded:nth-child(odd) {
  background: #fafafa;
}
app-scheduled-processes-admin .list-container mat-accordion mat-accordion mat-expansion-panel.mat-expanded:nth-child(odd) .mat-row:nth-child(even),
app-scheduled-processes-admin .list-container mat-accordion mat-accordion mat-expansion-panel.mat-expanded:nth-child(odd) .mat-header-row {
  background: #fafafa;
}
app-scheduled-processes-admin .list-container mat-accordion mat-accordion mat-expansion-panel.mat-expanded .mat-expansion-panel-content {
  height: unset !important;
}
app-scheduled-processes-admin .list-container mat-accordion mat-accordion mat-expansion-panel.mat-expanded .mat-expansion-panel-content .mat-expansion-panel-body {
  height: unset !important;
  overflow-y: unset !important;
}
app-scheduled-processes-admin .list-container mat-accordion mat-accordion mat-expansion-panel.mat-expanded .mat-expansion-panel-content .mat-expansion-panel-body .mat-header-row {
  z-index: 0 !important;
}
app-scheduled-processes-admin .list-container mat-accordion mat-accordion mat-expansion-panel.mat-expanded .mat-expansion-panel-content .mat-expansion-panel-body .mobile-title {
  display: none;
}
app-scheduled-processes-admin .list-container mat-accordion mat-accordion mat-expansion-panel.mat-expanded .mat-expansion-panel-content .mat-expansion-panel-body .status-bar {
  position: relative !important;
  z-index: 1;
}
app-scheduled-processes-admin .list-container mat-accordion mat-accordion mat-expansion-panel.mat-expanded .mat-expansion-panel-content .mat-expansion-panel-body .floating-action {
  min-width: 200px;
  height: 36px;
}

.menu-order.mat-card .mat-menu-content {
  padding: 0;
}
.menu-order.mat-card .mat-menu-content .container {
  padding: 16px;
}
.menu-order.mat-card .mat-menu-content .container .menu-title {
  color: #3f51b5;
  margin-bottom: 16px;
  padding-right: 16px;
}
.menu-order.mat-card .mat-menu-content .container .menu-title mat-icon {
  box-sizing: content-box !important;
  padding: 8px !important;
  min-width: 24px !important;
}
.menu-order.mat-card .mat-menu-content .container .menu-title .text {
  font-size: 16px;
  font-weight: 500;
}
.menu-order.mat-card .mat-menu-content .container mat-radio-group {
  margin-bottom: 16px;
}
.menu-order.mat-card .mat-menu-content .container mat-radio-group mat-radio-button {
  height: 40px;
}
.menu-order.mat-card .mat-menu-content .container mat-radio-group mat-radio-button .mat-radio-label {
  height: 100%;
  width: 100%;
}
.menu-order.mat-card .mat-menu-content .container mat-radio-group mat-radio-button .mat-radio-label .mat-radio-container {
  margin: 10px;
}
.menu-order.mat-card .mat-menu-content .container mat-radio-group mat-radio-button .mat-radio-label .mat-radio-label-content {
  padding: 0;
  margin-left: 16px;
}
.menu-order.mat-card .mat-menu-content .container .menu-footer {
  padding-top: 16px;
}

.table-content .header-primary button.mat-icon-button.close-scheduled-mobile,
.ref-content .header-primary button.mat-icon-button.close-scheduled-mobile {
  display: none;
}

button.mobile-trigger.mat-icon-button {
  display: none;
}

button.scheduled-trigger-mobile.mat-button.mat-accent {
  display: none;
}

@media (max-width: 599px) {
  .app-page mat-drawer-content .app-container .main-toolbar .mat-avatar .scheduled-actions {
    width: 100%;
    position: absolute;
    height: 50px;
    top: 100%;
    z-index: 1;
    background: #283593;
    justify-content: center !important;
    left: 0;
  }
  button.mobile-trigger.mat-icon-button {
    position: absolute;
    color: #fff;
    left: 16px;
    top: 16px;
    z-index: 1;
    display: block;
  }
  .menu-order.mat-card {
    width: 100vw;
    max-width: unset;
    position: absolute;
    right: 0;
    box-sizing: border-box;
  }
  app-scheduled-processes-admin .list-container mat-accordion mat-expansion-panel .mat-expansion-panel-header .mat-content .primary-text span > span {
    font-size: 0.7rem;
  }
  .show-mobile {
    display: block;
  }
  .hide-mobile {
    display: none !important;
  }
  .show-scheduled .mat-header-row {
    display: none !important;
  }
  .show-scheduled .mat-row {
    height: unset;
  }
  .show-scheduled .w-12 {
    flex-direction: column !important;
  }
  .show-scheduled .w-12 .mat-cell {
    width: 100% !important;
    padding: 8px 0;
  }
  .table-content .header-primary button.mat-icon-button.close-scheduled-mobile,
.ref-content .header-primary button.mat-icon-button.close-scheduled-mobile {
    display: block;
  }
  button.scheduled-trigger-mobile.mat-button.mat-accent {
    display: block;
  }
  .app-page .app-container .main-toolbar {
    height: 72px;
    min-height: 72px;
    position: relative;
  }
  .app-page .app-container .main-toolbar .mat-avatar .toolbar-line mat-icon {
    opacity: 0;
  }
  .app-page .app-container .main-toolbar .mat-avatar .mat-stroked-button .mat-button-wrapper span {
    display: none;
  }
  .app-page .app-container .main-toolbar .mat-avatar .mat-stroked-button {
    border: none;
    min-width: 40px;
    padding: 0;
  }
  .app-page .app-container .main-container {
    height: calc(100% - 72px) !important;
    padding-top: 50px !important;
  }
  .app-page .app-container {
    position: absolute;
    z-index: 9;
    width: 100vw;
    left: 100%;
    transition: left 0.2s ease;
  }
  .app-page .side-nav {
    width: 100vw;
    min-width: 100vw;
  }
  .app-page {
    position: relative;
    height: calc(100vh - 56px) !important;
  }
  .app-page .side-nav .side-nav-container .content .ou-title {
    height: 73px;
  }
  .app-page .side-nav .side-nav-container .content .tools {
    height: calc(100% - 74px - 56px - 56px - 56px - 49px);
  }
  .app-page .app-container.mobile-open {
    left: 0;
  }
  .main {
    width: 100%;
  }
  app-scheduled-processes-admin .list-container mat-accordion mat-expansion-panel .mat-expansion-panel-header .mat-content .primary-text span {
    display: block;
    white-space: break-spaces;
    line-break: auto;
  }
  app-scheduled-processes-admin .list-container mat-accordion mat-expansion-panel .mat-expansion-panel-header .mat-content .actions {
    flex-shrink: 0;
  }
  app-scheduled-processes-admin .list-container mat-accordion mat-expansion-panel .mat-expansion-panel-header .mat-content .mat-expansion-panel-header-title {
    flex-grow: 0 !important;
    flex-shrink: 1;
  }
  app-scheduled-processes-admin .list-container mat-accordion mat-expansion-panel .mat-expansion-panel-header .mat-content {
    justify-content: space-between;
  }
  app-scheduled-processes-admin .list-container mat-accordion mat-expansion-panel .mat-expansion-panel-header .mat-content .actions button {
    min-width: unset;
  }
  app-scheduled-processes-admin .list-container mat-accordion mat-accordion .accent-text span {
    display: block;
  }
  app-scheduled-processes-admin .list-container mat-accordion {
    height: calc(100% - 50px);
  }
  app-scheduled-processes-admin .list-container mat-accordion mat-accordion mat-expansion-panel.mat-expanded .mat-expansion-panel-content .mat-expansion-panel-body .mat-header-row {
    display: none !important;
  }
  app-scheduled-processes-admin .list-container mat-accordion mat-accordion mat-expansion-panel.mat-expanded .mat-expansion-panel-content .mat-expansion-panel-body .mat-row .w-12 {
    flex-direction: column !important;
    height: auto !important;
    justify-content: flex-start !important;
  }
  app-scheduled-processes-admin .list-container mat-accordion mat-accordion mat-expansion-panel.mat-expanded .mat-expansion-panel-content .mat-expansion-panel-body .mat-row {
    width: 100%;
    height: auto;
    padding: 16px 0;
  }
  app-scheduled-processes-admin .list-container mat-accordion mat-accordion mat-expansion-panel.mat-expanded .mat-expansion-panel-content .mat-expansion-panel-body .mat-row .w-12 .mat-cell {
    width: 100% !important;
    justify-content: center !important;
    align-items: flex-start !important;
    padding-left: 56px;
    flex-direction: column !important;
  }
  app-scheduled-processes-admin .list-container mat-accordion mat-accordion mat-expansion-panel.mat-expanded .mat-expansion-panel-content .mat-expansion-panel-body .mobile-title {
    display: block;
    font-size: 12px;
    color: #9e9e9e;
  }
  app-scheduled-processes-admin .list-container mat-accordion mat-accordion mat-expansion-panel.mat-expanded .mat-expansion-panel-content .mat-expansion-panel-body .mat-row .w-12 .mat-cell + .mat-cell {
    margin-top: 5px;
  }
  app-scheduled-processes-admin .list-container mat-accordion mat-accordion mat-expansion-panel.mat-expanded .mat-expansion-panel-content .mat-expansion-panel-body .mat-row + .mat-row {
    margin-top: 16px;
  }
  app-scheduled-processes-admin .list-container mat-accordion mat-accordion mat-expansion-panel.mat-expanded .mat-expansion-panel-content .mat-expansion-panel-body .floating-action {
    position: relative !important;
    opacity: 1;
    width: calc(100% - 56px);
    right: 0;
    margin-left: 56px;
    margin-top: 16px;
  }
  .app-page .modal .mat-card {
    border-radius: 0;
    box-shadow: none;
    width: 100vw;
    height: 100vh;
  }
  .app-page .modal .mat-card .modal-body {
    padding: 0 16px;
  }
  .app-page .modal .mat-card .modal-body .table-content,
.app-page .modal .mat-card .modal-body .ref-content {
    width: 100vw;
    height: 100%;
    position: absolute;
    z-index: 10;
    background: #fff;
    left: 100%;
    padding: 0 32px;
    box-sizing: border-box;
    transition: left 0.2 ease;
  }
  .app-page .modal .mat-card .modal-body .table-content.show-scheduled,
.app-page .modal .mat-card .modal-body .ref-content.show-scheduled {
    left: 0;
  }
  .app-page .modal .mat-card .modal-body .edition {
    width: 100vw;
    margin: 0;
  }
  app-home.ng-star-inserted {
    width: 100% !important;
    height: 100% !important;
    display: block !important;
  }
  .app-container {
    position: unset !important;
  }
  body app-root .app-page app-home .app-container .app-workarea.show-app-tools .app-tools {
    width: 100% !important;
  }
  .app-page .app-container .main-toolbar {
    min-height: 96px !important;
    height: 96px !important;
  }
  mat-icon.mat-icon.notranslate.fa-lg.fa.fa-home-alt.mat-icon-no-color {
    opacity: 1 !important;
  }
  .app-page .app-container .main-toolbar button.mat-focus-indicator.mat-raised-button.mat-button-base.mat-accent {
    right: 16px !important;
    z-index: 1 !important;
    position: fixed !important;
    bottom: 16px !important;
    height: 64px !important;
  }
  .app-page .app-container .main-toolbar button.mat-focus-indicator.mat-raised-button.mat-button-base.mat-accent .mat-button-wrapper span {
    display: none !important;
  }
  .app-page .app-container .main-toolbar button.mat-focus-indicator.mat-raised-button.mat-button-base.mat-accent .mat-button-wrapper mat-icon {
    margin: 0 !important;
  }
  .app-page .app-container .main-toolbar .mat-avatar .toolbar-line .title h3 {
    white-space: break-spaces !important;
    font-size: 18px !important;
    line-height: 22px !important;
    width: 100% !important;
    display: block !important;
  }
  body app-root .app-page app-home .app-container .app-workarea .app-main app-chapa .inside-chapa h2,
body app-root .app-page app-home .app-container .app-workarea .app-main app-chapa .inside-chapa h4 {
    max-width: 90% !important;
  }
  body app-root .app-page app-home .app-container .app-workarea .app-main .ou-cards {
    padding-bottom: 90px !important;
    max-height: calc(100vh - 114px - 96px) !important;
  }
  body app-root .app-page app-home .app-container .app-workarea .app-main .ou-cards .mat-card {
    width: 100% !important;
  }
  body app-root .app-page app-home .app-container .app-workarea .app-main .helper {
    height: auto !important;
    flex-wrap: wrap !important;
    padding: 16px 0 !important;
  }
  body app-root .app-page app-home .app-container .app-workarea .app-main .helper .search {
    width: 100% !important;
  }
  body app-root .app-page app-home .app-container .app-workarea .app-main .helper .status {
    float: right !important;
    width: 100% !important;
    justify-content: flex-end !important;
    min-height: 40px !important;
  }
  body app-root .app-page app-home .app-container .app-workarea .app-main .helper .search .field {
    width: 100% !important;
  }
  body app-root .app-page app-home .app-container .app-workarea .app-main .helper .search .field input {
    margin: 0 !important;
  }
  body app-root .app-page app-home .app-container .app-workarea .app-main .show-detail + .ou-detail {
    position: fixed !important;
    z-index: 3 !important;
    width: 100vw !important;
  }
  body app-root .app-page app-home .app-container .app-workarea .app-main .ou-cards .mat-card .mat-card-actions {
    opacity: 1 !important;
  }
  body app-root .app-page app-home .app-container .app-workarea .app-main .ou-detail .side-container mat-card.card-side .mat-card-actions {
    opacity: 1 !important;
  }
  .app-page .app-container .main-container .list-container {
    max-width: 100% !important;
  }
  .app-page .app-container .main-container {
    padding: 0 !important;
    height: calc(100vh - 96px) !important;
  }
  .app-page .app-container .main-toolbar .mat-avatar .toolbar-line mat-icon {
    opacity: 1 !important;
  }
  .px72 {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
  app-upload-form {
    max-height: calc(100% - 64px - 64px) !important;
  }
  .content.scrollable.px72.pb16 {
    width: 100% !important;
    display: block !important;
    padding: 8px 24px !important;
  }
  .content mat-card.mat-card {
    width: 100% !important;
    margin: 8px 0 !important;
  }
  .content mat-card.mat-card + mat-card.mat-card {
    margin-top: 16px !important;
  }
  .mat-card-header .mat-card-subtitle {
    line-break: anywhere !important;
  }
  .scheduled-actions {
    justify-content: space-between !important;
    position: fixed !important;
    bottom: 0 !important;
    z-index: 1 !important;
    color: #3f51b5 !important;
    width: 100% !important;
    right: 0 !important;
    padding-left: 88px !important;
    padding-right: 16px !important;
    height: 64px !important;
  }
  app-scheduled-processes-admin .list-container mat-accordion {
    height: calc(100% - 64px) !important;
  }
  .app-page .app-container .main-toolbar .mat-avatar .mat-stroked-button {
    margin-right: 0 !important;
    width: 52px !important;
    height: 52px !important;
  }
  .app-page .app-container .main-toolbar .mat-avatar .mat-stroked-button .mat-icon {
    margin-right: 0 !important;
  }
  .mat-stroked-button span.mat-button-wrapper {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  app-scheduled-processes-admin .list-container mat-accordion mat-accordion mat-expansion-panel.mat-expanded .mat-expansion-panel-content .mat-expansion-panel-body .floating-action {
    height: auto;
  }
}
body .filter-menu .mat-menu-content {
  padding: 0 !important;
}
body .filter-menu .mat-menu-content .menu-title {
  padding: 0 32px;
}
body .filter-menu .mat-menu-content .menu-body {
  padding: 32px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  min-width: 280px;
}
body .filter-menu .mat-menu-content .menu-footer {
  padding: 16px;
}
body app-secret-user mat-toolbar {
  background: none !important;
  min-width: 25vw;
  margin-top: -16px !important;
  margin-bottom: 16px !important;
}
body app-secret-user mat-toolbar mat-toolbar-row {
  padding: 0 !important;
}
body app-secret-user mat-toolbar mat-toolbar-row .toolbar-line {
  width: 100%;
}
body app-secret-user mat-toolbar mat-toolbar-row .toolbar-line .title mat-icon {
  width: 40px;
  color: #3f51b5;
}
body app-secret-user mat-toolbar mat-toolbar-row .toolbar-line .title h2 {
  font-size: 16px;
  line-height: 20px;
  color: #3f51b5;
}
body app-secret-user mat-toolbar mat-toolbar-row .toolbar-line .title h5 {
  font-size: 12px;
  line-height: 16px;
}
body app-secret-user .mat-dialog-content {
  padding: 16px 24px;
  position: relative;
}
body app-secret-user .mat-dialog-content mat-progress-bar {
  width: 100%;
  left: 0;
}
body app-secret-user .mat-dialog-content mat-slide-toggle {
  min-height: 40px;
  margin-right: 4px;
}
body app-secret-user .mat-dialog-content mat-slide-toggle.mat-checked .mat-slide-toggle-content {
  font-weight: 700;
  color: rgba(0, 0, 0, 0.87);
}
body app-secret-user .mat-dialog-content mat-slide-toggle .mat-slide-toggle-bar {
  margin-right: 16px !important;
}
body app-secret-user .mat-dialog-content mat-slide-toggle .mat-slide-toggle-content {
  font-size: 14px !important;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.67);
}
body app-secret-user .mat-dialog-content .warning {
  padding: 24px 0;
}
body app-secret-user .mat-dialog-content .warning h3 {
  margin: 0;
}
body app-secret-user .mat-dialog-content mat-progress-spinner {
  margin: 0 auto;
}
body app-secret-user .mat-dialog-actions {
  margin-bottom: -10px !important;
}
body app-root .app-page app-home .app-container {
  position: relative;
  height: 100vh;
  overflow: hidden;
}
body app-root .app-page app-home .app-container .app-workarea {
  height: 100vh;
  width: 100vw;
  position: relative;
}
body app-root .app-page app-home .app-container .app-workarea.show-app-tools .app-tools {
  transition: width 0.2s ease;
  width: 384px;
}
body app-root .app-page app-home .app-container .app-workarea.show-app-tools .app-main {
  padding-left: 384px;
  transition: padding-left 0.2s ease;
}
body app-root .app-page app-home .app-container .app-workarea .app-tools {
  width: 72px;
  height: 100%;
  position: absolute;
  background: #fff;
  transition: width 0.2s ease;
  overflow: hidden;
  left: 0;
  z-index: 3;
}
body app-root .app-page app-home .app-container .app-workarea .app-tools .menu-content {
  width: 100%;
}
body app-root .app-page app-home .app-container .app-workarea .app-tools mat-nav-list {
  width: 100%;
  padding: 0 !important;
}
body app-root .app-page app-home .app-container .app-workarea .app-tools mat-nav-list .mat-list-item {
  height: 72px !important;
}
body app-root .app-page app-home .app-container .app-workarea .app-tools mat-nav-list .mat-list-item.active * {
  color: #3f51b5;
}
body app-root .app-page app-home .app-container .app-workarea .app-tools mat-nav-list.navigation {
  max-height: calc(100vh - 96px - 96px);
  overflow-y: auto;
  overflow-x: hidden;
}
body app-root .app-page app-home .app-container .app-workarea .app-tools mat-nav-list.menu-trigger .mat-list-item {
  height: 96px !important;
  background: #eee;
}
body app-root .app-page app-home .app-container .app-workarea .app-tools mat-nav-list.menu-trigger .mat-list-text {
  color: #9e9e9e;
  font-size: 14px;
}
body app-root .app-page app-home .app-container .app-workarea .app-tools mat-nav-list.menu-logoff .mat-list-item {
  height: 96px !important;
  background: #f7eef1;
}
body app-root .app-page app-home .app-container .app-workarea .app-tools mat-nav-list.menu-logoff * {
  color: #ff4081;
}
body app-root .app-page app-home .app-container .app-workarea .app-main {
  height: 100%;
  width: 100%;
  background: #e0e0e0;
  overflow: visible;
  padding-left: 72px;
  transition: padding-left 0.2s ease;
  box-sizing: border-box;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .impersonated-status {
  background: rgba(255, 255, 255, 0.08);
  height: 40px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
body app-root .app-page app-home .app-container .app-workarea .app-main .impersonated-status mat-icon {
  width: 40px;
  margin: 0 8px !important;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .impersonated-status h3 {
  margin: 0;
  font-size: 14px;
  line-height: 14px;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .impersonated-status h5 {
  margin: 0;
  font-size: 12px;
  line-height: 12px;
  font-weight: 300;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .impersonated-status .mat-icon-button .mat-button-wrapper {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .impersonated-status .mat-icon-button .mat-button-wrapper .fa-stack {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .impersonated-status .mat-icon-button .mat-button-wrapper .fa-stack .fa-stack-1x {
  line-height: 40px;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .impersonated-status .mat-icon-button .mat-button-wrapper .fa-stack .fa-stack .fa-stack-2x {
  line-height: 40px;
  bottom: -1px;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .helper {
  width: calc(100% - 32px);
  transition: width 0.2s ease;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 16px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
body app-root .app-page app-home .app-container .app-workarea .app-main .helper .status {
  display: flex;
  align-items: center;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .helper .status button {
  width: 40px !important;
  min-width: unset;
  display: flex;
  justify-content: center;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .helper .status b {
  font-size: 13px;
  margin-left: 8px;
  color: #3f51b5;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .helper .search {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .helper .search .field {
  width: 40vw;
  display: flex;
  align-items: center;
  position: relative;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .helper .search .field .preffix {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 8px;
  width: 40px;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .helper .search .field input {
  padding: 0 48px 0 52px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  margin-right: 8px;
  width: 100%;
  outline: none;
  background: #eeeeee;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .helper .search .field input:focus {
  background: #fff;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .helper .search .field .suffix {
  position: absolute;
  right: 16px;
  background: none !important;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .helper .search button {
  width: 40px !important;
  min-width: unset;
  display: flex;
  justify-content: center;
}
body app-root .app-page app-home .app-container .app-workarea .app-main app-chapa {
  display: flex;
  width: 100%;
  height: calc(100% - 73px - 97px);
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background: #e0e0e0;
}
body app-root .app-page app-home .app-container .app-workarea .app-main app-chapa .inside-chapa {
  height: 100%;
  min-height: 25vw !important;
}
body app-root .app-page app-home .app-container .app-workarea .app-main app-chapa .inside-chapa .large-icon {
  height: 120px !important;
  width: 120px !important;
  border-radius: 70px;
  font-size: 70px !important;
  margin-bottom: 16px;
  background: #3f51b5;
  color: #e0e0e0;
}
body app-root .app-page app-home .app-container .app-workarea .app-main app-chapa .inside-chapa h2,
body app-root .app-page app-home .app-container .app-workarea .app-main app-chapa .inside-chapa h3 {
  text-align: center;
  margin: 0;
  color: #3f51b5;
}
body app-root .app-page app-home .app-container .app-workarea .app-main app-chapa .inside-chapa h4,
body app-root .app-page app-home .app-container .app-workarea .app-main app-chapa .inside-chapa h5 {
  margin: 0;
  text-align: center;
  max-width: 40vw;
  font-weight: 400;
  color: #616161;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .ou-cards {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 8px 0 8px 8px;
  max-height: calc(100vh - 72px - 96px);
  overflow-y: scroll;
  box-sizing: border-box;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .ou-cards .mat-card {
  width: calc((100% - 64px) / 4);
  margin: 8px;
  box-sizing: border-box;
  transition: width 0.2s ease;
  cursor: pointer;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .ou-cards .mat-card.not-able .mat-card-info *:not(mat-card-subtitle) {
  color: #9e9e9e !important;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .ou-cards .mat-card:hover:not(.active) {
  background: #eee;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .ou-cards .mat-card:hover:not(.active) .mat-card-actions {
  opacity: 1;
  transition: opacity 0.2s ease;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .ou-cards .mat-card mat-card-title.mat-card-title {
  font-size: 18px;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .ou-cards .mat-card .mat-card-actions {
  display: flex;
  justify-content: flex-end;
  opacity: 0;
  transition: opacity 0.2s ease;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .ou-cards .mat-card.active {
  background: #3f51b5;
  pointer-events: none;
  cursor: default;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .ou-cards .mat-card.active.not-able {
  background: #9e9e9e !important;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .ou-cards .mat-card.active.not-able .mat-card-info *:not(mat-card-subtitle) {
  color: #ffffff !important;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .ou-cards .mat-card.active .mat-card-actions {
  pointer-events: all;
  opacity: 1;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .ou-cards .mat-card.active * {
  color: white;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .ou-detail {
  position: absolute;
  width: 384px;
  background: #fff;
  height: 100%;
  right: -384px;
  top: 0;
  transition: right 0.2s ease;
  box-sizing: border-box;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .ou-detail .title {
  height: 96px;
  padding: 16px;
  box-sizing: border-box;
  background: #eee;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .ou-detail .title .mat-icon {
  width: 40px;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .ou-detail .title .text {
  color: #3f51b5;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .ou-detail .side-container {
  width: 100%;
  height: calc(100% - 96px);
  padding: 16px;
  box-sizing: border-box;
  overflow-y: auto;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .ou-detail .side-container mat-card mat-card-title {
  font-size: 16px;
  margin: 0;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .ou-detail .side-container mat-card mat-card-title + mat-card-title {
  font-size: 12px;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .ou-detail .side-container mat-card .side mat-card mat-card-subtitle {
  font-size: 12px;
  margin: 0;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .ou-detail .side-container mat-card + mat-card {
  margin-top: 16px;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .ou-detail .side-container mat-card.card-side {
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.12);
}
body app-root .app-page app-home .app-container .app-workarea .app-main .ou-detail .side-container mat-card.card-side.not-able .card-side-info * {
  color: #9e9e9e !important;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .ou-detail .side-container mat-card.card-side:hover {
  background: #eee;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .ou-detail .side-container mat-card.card-side:hover .mat-card-actions {
  opacity: 1;
  transition: opacity 0.2s ease;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .ou-detail .side-container mat-card.card-side .mat-card-actions {
  opacity: 0;
  transition: opacity 0.2s ease;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .ou-detail .side-container mat-card.card-side .card-side-info *:not(mat-card-subtitle) {
  color: #3f51b5;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .ou-detail .side-container mat-card.card-main {
  background: #3f51b5;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .ou-detail .side-container mat-card.card-main.not-able {
  background: #9e9e9e !important;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .ou-detail .side-container mat-card.card-main * {
  color: white;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .show-detail .mat-progress-bar {
  width: calc(100% - 384px - 72px);
  transition: width 0.2s ease;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .show-detail .main-toolbar {
  width: calc(100% - 384px);
  transition: width 0.2s ease;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .show-detail .helper {
  width: calc(100% - 384px - 32px);
  transition: width 0.2s ease;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .show-detail .ou-cards {
  width: calc(100% - 384px);
  transition: width 0.2s ease;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .show-detail .ou-cards .mat-card {
  width: calc((100% - 48px) / 3) !important;
  transition: width 0.2s ease;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .show-detail + .ou-detail {
  right: 0;
  transition: right 0.2s ease;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .demo-view .main-toolbar {
  background: #607d8b !important;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .demo-view .ou-cards .mat-card.active {
  background: #607d8b !important;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .demo-view .ou-cards .mat-card.active.not-able {
  background: #9e9e9e !important;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .demo-view .ou-cards .mat-card.active.not-able .mat-card-info *:not(mat-card-subtitle) {
  color: #ffffff !important;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .demo-view + .ou-detail .title .text {
  color: #607d8b;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .demo-view + .ou-detail .side-container mat-card.card-side .card-side-info *:not(mat-card-subtitle) {
  color: #607d8b;
}
body app-root .app-page app-home .app-container .app-workarea .app-main .demo-view + .ou-detail .side-container mat-card.card-main {
  background: #607d8b;
}
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container {
  position: relative;
  box-sizing: border-box;
  height: 100vh;
  overflow: hidden;
}
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container.show-tools .workarea .tools {
  width: 384px;
  transition: width 0.2s ease;
}
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container.show-tools .workarea .main {
  padding-left: 384px;
  transition: padding 0.2s ease;
}
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container.show-tools .workarea .main mat-toolbar.breadcrumb-nav {
  transition: width 0.2s ease;
  width: calc(100vw - 384px);
}
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container .workarea {
  height: 100vh;
  box-sizing: border-box;
}
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container .workarea .tools {
  width: 72px;
  height: 100%;
  position: absolute;
  background: #fff;
  transition: width 0.2s ease;
  overflow: hidden;
  left: 0;
  z-index: 6;
}
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container .workarea .tools .menu-content {
  width: 100%;
}
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container .workarea .tools .mat-list-base {
  width: 100%;
  padding: 0 !important;
}
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container .workarea .tools .mat-list-base .mat-list-item {
  height: 72px !important;
}
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container .workarea .tools .mat-list-base .mat-list-item.active * {
  color: #3f51b5;
}
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container .workarea .tools .mat-list-base.navigation {
  max-height: calc(100vh - 96px - 96px);
  overflow-y: auto;
  overflow-x: hidden;
}
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container .workarea .tools .mat-list-base.menu-trigger .mat-list-item {
  height: 96px !important;
  background: #eee;
}
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container .workarea .tools .mat-list-base.menu-trigger .mat-list-text {
  color: #9e9e9e;
  font-size: 14px;
}
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container .workarea .tools .mat-list-base.menu-version .mat-list-item {
  height: 96px !important;
  background: #e8eaf6;
}
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container .workarea .tools .mat-list-base.menu-version .mat-list-item .mat-list-text mat-icon {
  width: 40px;
}
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container .workarea .tools .mat-list-base.menu-version mat-icon,
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container .workarea .tools .mat-list-base.menu-version b {
  color: #3f51b5;
}
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container .workarea .main {
  width: 100%;
  height: 100%;
  padding-left: 72px;
  transition: padding 0.2s ease;
  box-sizing: border-box;
}
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container .workarea .main mat-toolbar.main-toolbar .toolbar-line h5 {
  opacity: 0;
  line-height: 30px;
  height: 30px;
}
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container .workarea .main mat-toolbar.breadcrumb-nav {
  position: fixed;
  z-index: 4;
  height: 96px;
  transition: height 0.2s ease;
  width: calc(100vw - 72px);
}
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container .workarea .main mat-toolbar.breadcrumb-nav.shrink {
  height: 20px;
  top: 48px;
  background: none !important;
  pointer-events: none;
  color: #fff;
  transition: height 0.2s ease;
}
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container .workarea .main mat-toolbar.breadcrumb-nav.shrink .breadcrumbs {
  height: 100%;
}
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container .workarea .main mat-toolbar.breadcrumb-nav.shrink .breadcrumbs .mat-icon-button {
  opacity: 0;
  pointer-events: none;
}
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container .workarea .main mat-toolbar.breadcrumb-nav.shrink .breadcrumbs mat-icon.main-icon {
  opacity: 0;
}
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container .workarea .main mat-toolbar.breadcrumb-nav .breadcrumbs {
  pointer-events: all;
  height: 36px;
}
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container .workarea .main mat-toolbar.breadcrumb-nav .breadcrumbs .mat-icon-button .mat-button-wrapper {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container .workarea .main mat-toolbar.breadcrumb-nav .breadcrumbs .mat-icon-button .mat-button-wrapper .fa-stack {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container .workarea .main mat-toolbar.breadcrumb-nav .breadcrumbs .mat-icon-button .mat-button-wrapper .fa-stack .fa-stack-1x {
  line-height: 40px;
}
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container .workarea .main mat-toolbar.breadcrumb-nav .breadcrumbs .mat-icon-button .mat-button-wrapper .fa-stack .fa-stack .fa-stack-2x {
  line-height: 40px;
  bottom: -1px;
}
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container .workarea .main mat-toolbar.breadcrumb-nav .breadcrumbs .mat-button {
  border-radius: 20px;
}
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container .workarea .main mat-toolbar.breadcrumb-nav .breadcrumbs .mat-button .mat-button-wrapper {
  display: flex;
  align-items: center;
}
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container .workarea .main mat-toolbar.breadcrumb-nav .breadcrumbs .mat-button .mat-button-wrapper .mat-icon {
  margin-right: -16px;
}
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container .workarea .main mat-toolbar.breadcrumb-nav .breadcrumbs .mat-icon {
  width: 40px;
}
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container .workarea .main mat-toolbar.breadcrumb-nav .breadcrumbs-actions {
  pointer-events: all;
}
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container .workarea .main app-chapa {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  padding-top: 96px;
  box-sizing: border-box;
  background: #e0e0e0;
}
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container .workarea .main app-chapa .inside-chapa {
  height: 100%;
  min-height: 25vw !important;
}
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container .workarea .main app-chapa .inside-chapa .large-icon {
  height: 120px !important;
  width: 120px !important;
  border-radius: 70px;
  font-size: 70px !important;
  margin-bottom: 16px;
  background: #3f51b5;
  color: #e0e0e0;
}
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container .workarea .main app-chapa .inside-chapa h2,
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container .workarea .main app-chapa .inside-chapa h3 {
  text-align: center;
  margin: 0;
  color: #3f51b5;
}
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container .workarea .main app-chapa .inside-chapa h4,
body app-root .app-page app-organizational-unit-admin .ou-admin.app-container .workarea .main app-chapa .inside-chapa h5 {
  margin: 0;
  text-align: center;
  max-width: 40vw;
  font-weight: 400;
  color: #616161;
}

.cdk-overlay-container {
  z-index: 5001 !important;
}

.dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 300px;
  border: 2px dashed #666;
  border-radius: 5px;
  background: white;
  margin: 10px 0;
}
.dropzone.hovering {
  border: 2px solid #666;
  color: #dadada !important;
}

.mat-list-icon {
  color: rgba(0, 0, 0, 0.54);
}

mat-icon.disabled {
  color: rgba(0, 0, 0, 0.27);
}

.apps-table {
  width: 100%;
}

.app-detail .detail-block + .detail-block {
  display: none;
}