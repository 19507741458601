html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

*::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: #e0e0e0;
}

*::-webkit-scrollbar-thumb {
  background: rgba(33, 33, 33, 0.25);
}

//mixin padding&margin
$sizes: 100;

@mixin margin-classes {
  @for $i from 0 through $sizes {
    $margin: $i * 1px;

    .m#{$i} {
      margin: $margin !important;
    }

    .ml#{$i} {
      margin-left: $margin !important;
    }

    .mr#{$i} {
      margin-right: $margin !important;
    }

    .mt#{$i} {
      margin-top: $margin !important;
    }

    .mb#{$i} {
      margin-bottom: $margin !important;
    }

    .mx#{$i} {
      margin-left: $margin !important;
      margin-right: $margin !important;
    }

    .my#{$i} {
      margin-top: $margin !important;
      margin-bottom: $margin !important;
    }
  }
}

@include margin-classes;

@mixin padding-classes {
  @for $i from 0 through $sizes {
    $padding: $i * 1px;

    .p#{$i} {
      padding: $padding !important;
    }

    .pl#{$i} {
      padding-left: $padding !important;
    }

    .pr#{$i} {
      padding-right: $padding !important;
    }

    .pt#{$i} {
      padding-top: $padding !important;
    }

    .pb#{$i} {
      padding-bottom: $padding !important;
    }

    .px#{$i} {
      padding-left: $padding !important;
      padding-right: $padding !important;
    }

    .py#{$i} {
      padding-top: $padding !important;
      padding-bottom: $padding !important;
    }
  }
}

@include padding-classes;

//estilos varios//
button {
  font-size: 16px !important;
}

.mat-progress-bar {
  position: absolute !important;
  z-index: 2;
}

.mat-form-field-wrapper {
  width: 100%;
}

.mat-list-icon.mat-icon {
  padding: 8px !important;
  box-sizing: content-box !important;
}

.mat-icon {
  font-size: 20px !important;
}

.mat-form-field-prefix {
  padding: 8px 10px;
  margin-right: 16px;
}


mat-form-field {
  &.date_form-field {
    box-sizing: border-box;

    .mat-form-field-infix {
      display: flex;

      mat-datepicker-toggle {
        position: absolute;
        right: 0;
        bottom: 0.5em;
      }
    }
  }
}

mat-toolbar-row .mat-icon :not(.mat-form-field-suffix .mat-icon) {
  padding: 8px !important;
  box-sizing: content-box !important;
}

.mat-raised-button mat-icon {
  font-size: 1.33333em;
  margin-right: 8px;
}

.mat-fab .mat-icon,
.mat-mini-fab .mat-icon {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.mat-horizontal-stepper-header-container {
  display: none !important;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

//estilos login//
.login {
  background: url("/../../assets/img/background.jpg") no-repeat center center;
  background-size: cover;

  .login-card {
    max-height: calc(100vh - 56px);
    overflow: hidden;

    .mat-card-header {
      margin-bottom: 24px;

      .version {
        opacity: 0.7;
      }

      .mat-card-header-text {
        width: 100%;

        .brand {
          height: 25vh;
          max-height: 9rem;
          background: url("/../../assets/img/logo.png") no-repeat center center;
          background-size: contain;
          margin-bottom: 15px;
        }

        .description {
          font-size: 0.7rem;
          text-align: center;
        }
      }
    }

    .mat-horizontal-content-container {
      padding: 0 !important;

      .mat-card-content {
        padding: 0 16px 16px !important;
        margin-bottom: 0 !important;

        h3 {
          margin-bottom: 32px;
        }

        .mat-card-footer {
          margin: 16px 0 0 0 !important;
        }
      }
    }

    .mat-progress-bar {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}

//estilos frame//
.app-page {
  height: 100vh;
  width: 100vw;
  background: #e0e0e0;
  overflow: hidden;
  position: relative;

  .modal {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    animation: fade-in 0.2s ease;

    .mat-card {
      border-radius: 4px;
      padding: 0 16px;

      .header-primary {
        padding: 0 !important;
      }

      .modal-body {
        padding: 0 16px;

        .edition {
          min-width: 15vw;
          margin-left: 16px;

          .edition-content {
            padding: 16px 0;
          }
        }

        .table-content {
          min-width: 50vw;
          margin-right: 16px;
          max-height: 80vh;
        }

        .ref-content {
          margin-right: 16px;
          max-height: 80vh;
        }
      }

      .modal-footer {
        padding: 16px 0;
      }
    }

    .wizard-add {
      min-width: 25vw;
      min-height: 15vh;
      position: relative;
      border-radius: 4px;
      overflow: hidden;

      .load-window {
        width: 100%;
        height: 100%;
        top: 0;
      }

      .add-menu {
        background: #fff;
      }

      .mat-horizontal-content-container {
        padding: 0;
      }

      mat-toolbar {
        .mat-avatar {
          font-size: 16px;
          width: calc(100% - 40px - 16px) !important;
          min-width: unset !important;

          .toolbar-line {
            width: 100%;

            mat-icon {
              box-sizing: content-box !important;
              padding: 8px !important;
              min-width: 24px !important;
            }

            .title {
              width: calc(100% - 40px - 16px - 16px);

              h3 {
                font-size: 20px;
                line-height: 22px;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              h5 {
                font-size: 12px;
                line-height: 14px;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }

      .wizard-body {
        padding: 32px 32px 0;
        max-height: calc(90vh - 64px - 68px);
        overflow: auto;

        app-upload-form {
          .dropzone {
            padding: 32px !important;
          }
        }

        form {
          padding: 0 !important;
        }
      }

      .wizard.footer {
        padding: 16px 32px;
      }
    }
  }

  .app-container {
    height: 100vh !important;
    min-width: calc(100vw - 384px);
    position: relative;
    background: #fff;

    .load-window {
      position: absolute;
      z-index: 101;
      background: white;
      top: 128px;
      width: 100%;
      height: calc(100% - 128px);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .main-toolbar {
      min-height: 96px;
      height: 96px;

      .mat-avatar {
        font-size: 16px;
        width: calc(100% - 40px - 16px) !important;
        min-width: unset !important;

        .toolbar-line {
          width: 100%;

          mat-icon {
            box-sizing: content-box !important;
            padding: 8px !important;
            min-width: 24px !important;
          }

          .title {
            width: calc(100% - 40px - 16px - 16px);

            h3 {
              font-size: 20px;
              line-height: 22px;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            h5 {
              font-size: 12px;
              line-height: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        .mat-stroked-button {
          min-width: unset !important;

          .mat-icon {
            margin-right: 8px !important;
            box-sizing: content-box !important;
          }
        }
      }

      .mat-raised-button {
        .mat-button-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;

          .mat-icon {
            box-sizing: content-box !important;
            margin-right: 8px;

            & + span {
              padding-right: 8px;
            }
          }
        }
      }
    }

    .main-container {
      background: #fff;
      height: calc(100% - 96px);

      .list-container {
        position: relative;
      }
    }

    .side-container.darker {
      border-left: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
}

//table styles
.mat-table {
  height: 100%;
  overflow: auto;

  &.padded {
    height: calc(100% - 64px);

    .w-12 {
      padding: 0 16px;

      .center {
        text-align: center;
      }
    }
  }

  .sticky {
    top: 0px;
    position: sticky;
    z-index: 10;
    align-items: center;
  }

  .icon {
    height: 100%;
    width: 72px;
    min-width: 72px;

    .mat-icon {
      padding: 8px !important;
      box-sizing: content-box !important;
    }

    &.bulb button {
      color: #ffeb3b;
    }
  }

  .w-12 {
    height: 100%;
    width: 100%;
    min-width: 100%;

    .sorteable {
      cursor: pointer;

      .sort-icon {
        .mat-icon {
          font-size: 15px !important;
        }
      }
    }

    .icon + & {
      height: 100%;
      width: calc(100% - 72px);
      min-width: calc(100% - 72px);
    }

    .w-1 {
      height: 100%;
      width: 72px;
      min-width: 72px;

      .mat-icon {
        padding: 8px !important;
        box-sizing: content-box !important;
      }

      &.bulb button {
        color: #ffeb3b;
      }
    }

    .w-1-5 {
      height: 100%;
      width: calc((100% / 12) * 1.5);
      min-width: calc((100% / 12) * 1.5);
    }

    .w-2 {
      height: 100%;
      width: calc((100% / 12) * 2);
      min-width: calc((100% / 12) * 2);
    }

    .w-3 {
      height: 100%;
      width: calc((100% / 12) * 3);
      min-width: calc((100% / 12) * 3);
    }

    .w-4 {
      height: 100%;
      width: calc((100% / 12) * 4);
      min-width: calc((100% / 12) * 4);
    }

    .w-5 {
      height: 100%;
      width: calc((100% / 12) * 5);
      min-width: calc((100% / 12) * 5);
    }

    .w-6 {
      height: 100%;
      width: calc((100% / 12) * 6);
      min-width: calc((100% / 12) * 6);
    }
  }

  .mat-header-row {
    min-height: 64px;
    width: 100%;
    background: #fff;
    z-index: 100 !important;
  }

  .mat-row {
    height: 56px;
    width: 100%;
    position: relative;
    cursor: default;
    transition: background 0.2s ease;

    .floating-action {
      position: absolute;
      right: 16px;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.2s ease;
      background: #eeeeee;
      z-index: 1;
    }

    &:hover {
      background: #eeeeee !important;
      transition: all 0.2s ease;
      transition-delay: 0.05s;

      .floating-action {
        opacity: 1;
        pointer-events: all;
        transition: opacity 0.2s ease;
      }
    }

    &:nth-child(odd) {
      background: #f5f5f5;
      transition: all 0.2s ease;
      transition-delay: 0.05s;

      .floating-action {
        background: #f5f5f5;
      }
    }

    &:focus,
    &:focus:hover,
    &:focus:hover .sticky-actions {
      outline: none;
    }

    &.active {
      background: #90caf9 !important;
      transition: all 0.2s ease;
      transition-delay: 0.05s;

      .floating-action {
        background: #90caf9;
        transition: all 0.2s ease;
      }

      .w-12 div {
        color: #fff !important;
      }

      &:hover,
      &:hover .sticky-actions {
        background: #90caf9 !important;
        transition: all 0.2s ease;
        transition-delay: 0.05s;
      }
    }

    &.disabled {
      div,
      .bulb button {
        color: #cfd8dc !important;
      }

      .floating-action {
        color: #cfd8dc !important;
      }
    }
  }
}

.certificates_option.w-12 {
  width: 100%;
  min-width: 100%;
  flex-wrap: wrap;

  .w-6 {
    width: calc((100% / 12) * 6);
    min-width: min-content;
    flex-grow: 1;
  }
}

//seguridad
.sticky-header {
  position: sticky;
  top: 0;
  bottom: 0;
  background: #fff;
  z-index: 5;
  box-sizing: content-box;

  h3.mat-subheader {
    height: 64px !important;
  }
}

.header-primary {
  height: 64px !important;
}

.mat-list-item {
  box-sizing: border-box;
}

//documents details
app-documentation-type-detail {
  .mat-expansion-panel-body {
    padding: 0 0 16px !important;
    height: min-content;
  }

  .mat-expansion-panel-content {
    overflow-y: auto;
    overflow-y: overlay;
  }
}

[fxlayoutgap="16px"] mat-form-field.w1-4 {
  width: calc(25% - ((16px * 3) / 4));
}

[fxlayoutgap="16px"] mat-form-field.w1-2 {
  width: calc(50% - ((16px * 1) / 2));
}

[fxlayoutgap="8px"] mat-form-field.w1-4 {
  width: calc(25% - ((8px * 3) / 4));
}

[fxlayoutgap="8px"] mat-form-field.w1-2 {
  width: calc(50% - ((8px * 1) / 2));
}

.list-container {
  position: relative;
}

.content {
  height: 100%;
}

.scrollable {
  overflow-y: auto;
  overflow-y: overlay;
  height: 100%;
}

app-upload-form {
  display: flex;
  width: 100%;
  align-items: stretch;
  flex-grow: 2;
  justify-content: center;
  max-height: calc(100% - 64px - 64px);
  box-sizing: border-box;
}

.catcher {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.15);
  transition: left 0.1s ease-out;
  left: 100%;
  z-index: 5000;
}

.catcher.on {
  left: 0;
  transition: left 0.2s ease-in;
}

.title-clickeable {
  cursor: pointer;
}

.list-title {
  color: rgba(0, 0, 0, 0.54);
}

.list-title span {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}

.primary-text {
  color: #4051b5 !important;
  font-size: 14px !important;
  font-weight: 500 !important;

  &.big {
    font-size: 18px !important;
  }
}

.accent-text {
  color: #ff4081 !important;
}

//dropzone

.dropzone button.mat-stroked-button {
  background: #fff;
}

.dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed #666;
  border-radius: 5px;
  height: unset !important;
  margin: 0 !important;
  background: #e8eaf6 !important;
  width: 100%;

  &.hovering {
    border: 2px solid #666;
    color: #bfbfbf !important;
  }
}

.dropzone h3,
.dropzone h6 {
  margin: 0;
}

.dropzone .file {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file-upload-list {
  padding: 0;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 16px;

  mat-list-item {
    border-radius: 50px;
    margin-bottom: 8px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    overflow: hidden;
    background: #fff;
    transition: background 0.2s ease;

    .mat-list-text {
      width: calc(100% - 16px - 36px) !important;
    }

    .sliding-actions {
      transition: all 0.2s ease;
      position: absolute;
      right: -40px;
    }
  }

  mat-list-item:hover {
    background: #fafafa;

    .mat-list-text {
      width: calc(100% - 16px - 32px - 40px) !important;
    }

    .sliding-actions {
      right: 16px;
      transition: all 0.2s ease;
    }
  }
}

.mat-radio-label-content {
  font-size: 14px !important;
}

.mat-slide-toggle-content {
  font-size: 12px !important;
}

span.mat-checkbox-label {
  font-size: 12px !important;
}

mat-bottom-sheet-container {
  max-width: 40vw !important;
}

.btn-label {
  text-align: left;
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
}

.btn-description {
  text-align: left;
  font-size: 14px;
  line-height: 18px;
}

.list-favourite {
  position: relative;
}

.list-favourite .header-primary {
  margin-bottom: 72px !important;
}

.list-favourite .item-action.favourite {
  position: absolute;
  top: 64px;
}

.list-favourite .item-action.favourite .mat-line {
  color: #ff4081 !important;
}

.list-favourite .item-action {
  cursor: pointer;
}

.list-favourite .item-action button {
  visibility: hidden;
}

.list-favourite .item-action:hover {
  background: rgba(0, 0, 0, 0.04);
}

.list-favourite .item-action:hover button {
  visibility: visible;
}

.disabled {
  color: rgba(0, 0, 0, 0.24);
}

b.required_candidate.ng-star-inserted {
  position: absolute;
  top: 0;
  right: 0;
}

.metadata_item-candidate_status {
  position: relative;
}

.logo-section {
  background: #e8eaf6 !important;

  h3 {
    background: #e8eaf6 !important;
    color: rgba(0, 0, 0, 0.87) !important;
  }

  .logo-container {
    background: #e8eaf6 !important;
    height: 200px;
    width: 100%;
    box-sizing: border-box;
  }

  img.logo-preview {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }
}

// doc-type styles
.documentation-type-container {
  width: 100% !important;
  min-width: 100% !important;
}

.documentation-type-details-container {
  width: 100% !important;
  min-width: 100% !important;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.document-detail {
  height: 100%;
  position: relative;

  .title {
    width: 100%;
    height: 64px !important;
    box-sizing: border-box;
  }

  .detail-panel {
    height: calc(100% - 65px);

    .resume {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: auto;
      display: flex;
      flex: 1;
      width: 100%;
      overflow: hidden;
      flex-direction: column;

      .view {
        height: calc(100% - 1px);
        width: 100%;

        .title {
          width: 100%;
          height: 64px !important;
          box-sizing: border-box;
        }

        mat-list {
          height: calc(100% - 64px);
          width: 100%;
          overflow-y: auto !important;
          overflow-y: overlay !important;
          box-sizing: border-box;

          .mat-subheader:first-child {
            margin-top: 0 !important;
          }

          .mat-line {
            white-space: normal !important;
            overflow: unset !important;
            text-overflow: unset !important;
          }
        }
      }
    }

    .edit {
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 1;
      background: #fff;
      top: 0;

      .title {
        width: 100%;
        height: 64px !important;
        box-sizing: border-box;
      }

      form {
        height: calc(100% - 64px);
        width: 100%;
        overflow-y: auto !important;
        overflow-y: overlay !important;

        .form-content {
          width: 100%;
        }

        .form-footer {
          width: 100%;
        }
      }
    }

    mat-action-list {
      padding-top: 0 !important;
      width: 100%;

      mat-list-item {
        height: 48px !important;

        &:hover {
          background: rgba(0, 0, 0, 0.12);
        }
      }
    }
  }

  .option-panel {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 2;

    .view {
      height: 100%;
      width: 100%;

      .title {
        width: 100%;
        height: 64px !important;
        box-sizing: border-box;
      }

      mat-list {
        height: calc(100% - 64px);
        width: 100%;
        overflow-y: auto !important;
        overflow-y: overlay !important;
        box-sizing: border-box;

        .mat-subheader:first-child {
          margin-top: 0 !important;
        }

        .mat-line {
          white-space: normal !important;
          overflow: unset !important;
          text-overflow: unset !important;
        }
      }
    }

    .edit {
      height: 100%;
      width: 100%;

      .title {
        width: 100%;
        height: 64px !important;
        box-sizing: border-box;
      }

      form {
        height: calc(100% - 64px);
        width: 100%;
        overflow-y: auto !important;
        overflow-y: overlay !important;

        .form-content {
          width: 100%;
        }

        .form-footer {
          width: 100%;
        }
      }
    }
  }
}

app-validate-paycheck {
  .verification-response {
    * {
      margin: 0;
    }

    h3 {
      color: #607d8b;
      padding-bottom: 16px;
    }
  }

  .verification-list {
    background: #009688;
    padding: 16px;
    border-radius: 4px;
    color: #fff;
    margin-bottom: 16p;

    * {
      margin: 0;
    }
  }
}

.version-config {
  height: 48px;
  display: flex;
  align-items: center;
  color: #fff;
  position: absolute;
  right: 16px;
  bottom: 0;

  b {
    margin-left: 8px;
  }

  button {
    margin-left: 16px;
  }
}

//processes
app-scheduled-processes-admin {
  .list-container {
    height: 100%;

    mat-accordion {
      height: 100%;
      display: block;

      mat-icon {
        box-sizing: content-box !important;
        padding: 8px !important;
        min-width: 24px !important;
      }

      mat-expansion-panel {
        margin: 0 !important;
        box-shadow: none !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        border-radius: 0 !important;

        .mat-expansion-panel-header {
          padding: 0 16px;

          &[aria-disabled="true"] * {
            color: rgba(0, 0, 0, 0.26) !important;
          }

          .mat-content {
            align-items: center;

            .actions {
              button {
                min-width: 200px;
                height: 36px;

                mat-icon {
                  padding: 0 !important;
                  margin-right: 8px !important;
                }
              }
            }
          }
        }

        &.mat-expanded {
          height: calc(100% - (49px * 2));

          .mat-expansion-panel-header {
            z-index: +1;
            background: #fff;
            position: relative;
            box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
              0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
          }

          .mat-expansion-panel-content {
            height: calc(100% - 63px);

            .mat-expansion-panel-body {
              height: 100%;
              overflow-y: auto;
              overflow-y: overlay;
              padding: 0 !important;
            }
          }
        }
      }

      mat-accordion {
        height: unset !important;
        display: unset !important;

        .mat-header-cell {
          padding: 0 8px;

          span {
            line-height: 1;
          }
        }

        .mat-cell {
          padding: 8px;

          span {
            line-height: 1;
          }
        }

        * {
          box-shadow: none !important;
        }

        .mat-table.padded {
          overflow-x: hidden;
        }

        mat-expansion-panel {
          border-bottom: 0;

          &.mat-expanded {
            height: unset !important;

            .mat-expansion-panel-header {
              z-index: unset !important;
              background: unset !important;
              position: unset !important;
              box-shadow: unset !important;
            }

            &:nth-child(odd) {
              background: #fafafa;

              .mat-row:nth-child(even),
              .mat-header-row {
                background: #fafafa;
              }
            }

            .mat-expansion-panel-content {
              height: unset !important;

              .mat-expansion-panel-body {
                height: unset !important;
                overflow-y: unset !important;

                .mat-header-row {
                  z-index: 0 !important;
                }

                .mobile-title {
                  display: none;
                }

                .status-bar {
                  position: relative !important;
                  z-index: 1;
                }

                .floating-action {
                  min-width: 200px;
                  height: 36px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.menu-order.mat-card {
  .mat-menu-content {
    padding: 0;

    .container {
      padding: 16px;

      .menu-title {
        color: #3f51b5;
        margin-bottom: 16px;
        padding-right: 16px;

        mat-icon {
          box-sizing: content-box !important;
          padding: 8px !important;
          min-width: 24px !important;
        }

        .text {
          font-size: 16px;
          font-weight: 500;
        }
      }

      mat-radio-group {
        margin-bottom: 16px;

        mat-radio-button {
          height: 40px;

          .mat-radio-label {
            height: 100%;
            width: 100%;

            .mat-radio-container {
              margin: 10px;
            }

            .mat-radio-label-content {
              padding: 0;
              margin-left: 16px;
            }
          }
        }
      }

      .menu-footer {
        padding-top: 16px;
      }
    }
  }
}

//mobile version
.table-content,
.ref-content {
  .header-primary button.mat-icon-button.close-scheduled-mobile {
    display: none;
  }
}

button.mobile-trigger.mat-icon-button {
  display: none;
}

button.scheduled-trigger-mobile.mat-button.mat-accent {
  display: none;
}

@media (max-width: 599px) {
  .app-page
    mat-drawer-content
    .app-container
    .main-toolbar
    .mat-avatar
    .scheduled-actions {
    width: 100%;
    position: absolute;
    height: 50px;
    top: 100%;
    z-index: 1;
    background: #283593;
    justify-content: center !important;
    left: 0;
  }

  button.mobile-trigger.mat-icon-button {
    position: absolute;
    color: #fff;
    left: 16px;
    top: 16px;
    z-index: 1;
    display: block;
  }

  .menu-order.mat-card {
    width: 100vw;
    max-width: unset;
    position: absolute;
    right: 0;
    box-sizing: border-box;
  }

  app-scheduled-processes-admin
    .list-container
    mat-accordion
    mat-expansion-panel
    .mat-expansion-panel-header
    .mat-content
    .primary-text
    span
    > span {
    font-size: 0.7rem;
  }

  .show-mobile {
    display: block;
  }

  .hide-mobile {
    display: none !important;
  }

  .show-scheduled .mat-header-row {
    display: none !important;
  }

  .show-scheduled .mat-row {
    height: unset;
  }

  .show-scheduled .w-12 {
    flex-direction: column !important;
  }

  .show-scheduled .w-12 .mat-cell {
    width: 100% !important;
    padding: 8px 0;
  }

  .table-content,
  .ref-content {
    .header-primary button.mat-icon-button.close-scheduled-mobile {
      display: block;
    }
  }

  button.scheduled-trigger-mobile.mat-button.mat-accent {
    display: block;
  }

  .app-page .app-container .main-toolbar {
    height: 72px;
    min-height: 72px;
    position: relative;
  }

  .app-page .app-container .main-toolbar .mat-avatar .toolbar-line mat-icon {
    opacity: 0;
  }

  .app-page
    .app-container
    .main-toolbar
    .mat-avatar
    .mat-stroked-button
    .mat-button-wrapper
    span {
    display: none;
  }

  .app-page .app-container .main-toolbar .mat-avatar .mat-stroked-button {
    border: none;
    min-width: 40px;
    padding: 0;
  }

  .app-page .app-container .main-container {
    height: calc(100% - 72px) !important;
    padding-top: 50px !important;
  }

  .app-page .app-container {
    position: absolute;
    z-index: 9;
    width: 100vw;
    left: 100%;
    transition: left 0.2s ease;
  }

  .app-page .side-nav {
    width: 100vw;
    min-width: 100vw;
  }

  .app-page {
    position: relative;
    height: calc(100vh - 56px) !important;
  }

  .app-page .side-nav .side-nav-container .content .ou-title {
    height: 73px;
  }

  .app-page .side-nav .side-nav-container .content .tools {
    height: calc(100% - 74px - 56px - 56px - 56px - 49px);
  }

  .app-page .app-container.mobile-open {
    left: 0;
  }

  .main {
    width: 100%;
  }

  app-scheduled-processes-admin
    .list-container
    mat-accordion
    mat-expansion-panel
    .mat-expansion-panel-header
    .mat-content
    .primary-text
    span {
    display: block;
    white-space: break-spaces;
    line-break: auto;
  }

  app-scheduled-processes-admin
    .list-container
    mat-accordion
    mat-expansion-panel
    .mat-expansion-panel-header
    .mat-content
    .actions {
    flex-shrink: 0;
  }

  app-scheduled-processes-admin
    .list-container
    mat-accordion
    mat-expansion-panel
    .mat-expansion-panel-header
    .mat-content
    .mat-expansion-panel-header-title {
    flex-grow: 0 !important;
    flex-shrink: 1;
  }

  app-scheduled-processes-admin
    .list-container
    mat-accordion
    mat-expansion-panel
    .mat-expansion-panel-header
    .mat-content {
    justify-content: space-between;
  }

  app-scheduled-processes-admin
    .list-container
    mat-accordion
    mat-expansion-panel
    .mat-expansion-panel-header
    .mat-content
    .actions
    button {
    min-width: unset;
  }

  app-scheduled-processes-admin
    .list-container
    mat-accordion
    mat-accordion
    .accent-text
    span {
    display: block;
  }

  app-scheduled-processes-admin .list-container mat-accordion {
    height: calc(100% - 50px);
  }

  app-scheduled-processes-admin
    .list-container
    mat-accordion
    mat-accordion
    mat-expansion-panel.mat-expanded
    .mat-expansion-panel-content
    .mat-expansion-panel-body
    .mat-header-row {
    display: none !important;
  }

  app-scheduled-processes-admin
    .list-container
    mat-accordion
    mat-accordion
    mat-expansion-panel.mat-expanded
    .mat-expansion-panel-content
    .mat-expansion-panel-body
    .mat-row
    .w-12 {
    flex-direction: column !important;
    height: auto !important;
    justify-content: flex-start !important;
  }

  app-scheduled-processes-admin
    .list-container
    mat-accordion
    mat-accordion
    mat-expansion-panel.mat-expanded
    .mat-expansion-panel-content
    .mat-expansion-panel-body
    .mat-row {
    width: 100%;
    height: auto;
    padding: 16px 0;
  }

  app-scheduled-processes-admin
    .list-container
    mat-accordion
    mat-accordion
    mat-expansion-panel.mat-expanded
    .mat-expansion-panel-content
    .mat-expansion-panel-body
    .mat-row
    .w-12
    .mat-cell {
    width: 100% !important;
    justify-content: center !important;
    align-items: flex-start !important;
    padding-left: 56px;
    flex-direction: column !important;
  }

  app-scheduled-processes-admin
    .list-container
    mat-accordion
    mat-accordion
    mat-expansion-panel.mat-expanded
    .mat-expansion-panel-content
    .mat-expansion-panel-body
    .mobile-title {
    display: block;
    font-size: 12px;
    color: #9e9e9e;
  }

  app-scheduled-processes-admin
    .list-container
    mat-accordion
    mat-accordion
    mat-expansion-panel.mat-expanded
    .mat-expansion-panel-content
    .mat-expansion-panel-body
    .mat-row
    .w-12
    .mat-cell
    + .mat-cell {
    margin-top: 5px;
  }

  app-scheduled-processes-admin
    .list-container
    mat-accordion
    mat-accordion
    mat-expansion-panel.mat-expanded
    .mat-expansion-panel-content
    .mat-expansion-panel-body
    .mat-row
    + .mat-row {
    margin-top: 16px;
  }

  app-scheduled-processes-admin
    .list-container
    mat-accordion
    mat-accordion
    mat-expansion-panel.mat-expanded
    .mat-expansion-panel-content
    .mat-expansion-panel-body
    .floating-action {
    position: relative !important;
    opacity: 1;
    width: calc(100% - 56px);
    right: 0;
    margin-left: 56px;
    margin-top: 16px;
  }

  .app-page .modal .mat-card {
    border-radius: 0;
    box-shadow: none;
    width: 100vw;
    height: 100vh;

    .modal-body {
      padding: 0 16px;

      .table-content,
      .ref-content {
        width: 100vw;
        height: 100%;
        position: absolute;
        z-index: 10;
        background: #fff;
        left: 100%;
        padding: 0 32px;
        box-sizing: border-box;
        transition: left 0.2 ease;

        &.show-scheduled {
          left: 0;
        }
      }

      .edition {
        width: 100vw;
        margin: 0;
      }
    }
  }

  app-home.ng-star-inserted {
    width: 100% !important;
    height: 100% !important;
    display: block !important;
  }

  .app-container {
    position: unset !important;
  }

  body
    app-root
    .app-page
    app-home
    .app-container
    .app-workarea.show-app-tools
    .app-tools {
    width: 100% !important;
  }

  .app-page .app-container .main-toolbar {
    min-height: 96px !important;
    height: 96px !important;
  }

  mat-icon.mat-icon.notranslate.fa-lg.fa.fa-home-alt.mat-icon-no-color {
    opacity: 1 !important;
  }

  .app-page
    .app-container
    .main-toolbar
    button.mat-focus-indicator.mat-raised-button.mat-button-base.mat-accent {
    right: 16px !important;
    z-index: 1 !important;
    position: fixed !important;
    bottom: 16px !important;
    height: 64px !important;
  }

  .app-page
    .app-container
    .main-toolbar
    button.mat-focus-indicator.mat-raised-button.mat-button-base.mat-accent
    .mat-button-wrapper
    span {
    display: none !important;
  }

  .app-page
    .app-container
    .main-toolbar
    button.mat-focus-indicator.mat-raised-button.mat-button-base.mat-accent
    .mat-button-wrapper
    mat-icon {
    margin: 0 !important;
  }

  .app-page .app-container .main-toolbar .mat-avatar .toolbar-line .title h3 {
    white-space: break-spaces !important;
    font-size: 18px !important;
    line-height: 22px !important;
    width: 100% !important;
    display: block !important;
  }

  body
    app-root
    .app-page
    app-home
    .app-container
    .app-workarea
    .app-main
    app-chapa
    .inside-chapa
    h2,
  body
    app-root
    .app-page
    app-home
    .app-container
    .app-workarea
    .app-main
    app-chapa
    .inside-chapa
    h4 {
    max-width: 90% !important;
  }

  body
    app-root
    .app-page
    app-home
    .app-container
    .app-workarea
    .app-main
    .ou-cards {
    padding-bottom: 90px !important;
    max-height: calc(100vh - 114px - 96px) !important;
  }

  body
    app-root
    .app-page
    app-home
    .app-container
    .app-workarea
    .app-main
    .ou-cards
    .mat-card {
    width: 100% !important;
  }

  body
    app-root
    .app-page
    app-home
    .app-container
    .app-workarea
    .app-main
    .helper {
    height: auto !important;
    flex-wrap: wrap !important;
    padding: 16px 0 !important;
  }

  body
    app-root
    .app-page
    app-home
    .app-container
    .app-workarea
    .app-main
    .helper
    .search {
    width: 100% !important;
  }

  body
    app-root
    .app-page
    app-home
    .app-container
    .app-workarea
    .app-main
    .helper
    .status {
    float: right !important;
    width: 100% !important;
    justify-content: flex-end !important;
    min-height: 40px !important;
  }

  body
    app-root
    .app-page
    app-home
    .app-container
    .app-workarea
    .app-main
    .helper
    .search
    .field {
    width: 100% !important;
  }

  body
    app-root
    .app-page
    app-home
    .app-container
    .app-workarea
    .app-main
    .helper
    .search
    .field
    input {
    margin: 0 !important;
  }

  body
    app-root
    .app-page
    app-home
    .app-container
    .app-workarea
    .app-main
    .show-detail
    + .ou-detail {
    position: fixed !important;
    z-index: 3 !important;
    width: 100vw !important;
  }

  body
    app-root
    .app-page
    app-home
    .app-container
    .app-workarea
    .app-main
    .ou-cards
    .mat-card
    .mat-card-actions {
    opacity: 1 !important;
  }

  body
    app-root
    .app-page
    app-home
    .app-container
    .app-workarea
    .app-main
    .ou-detail
    .side-container
    mat-card.card-side
    .mat-card-actions {
    opacity: 1 !important;
  }

  .app-page .app-container .main-container .list-container {
    max-width: 100% !important;
  }

  .app-page .app-container .main-container {
    padding: 0 !important;
    height: calc(100vh - 96px) !important;
  }

  .app-page .app-container .main-toolbar .mat-avatar .toolbar-line mat-icon {
    opacity: 1 !important;
  }

  .px72 {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }

  app-upload-form {
    max-height: calc(100% - 64px - 64px) !important;
  }

  .content.scrollable.px72.pb16 {
    width: 100% !important;
    display: block !important;
    padding: 8px 24px !important;
  }

  .content mat-card.mat-card {
    width: 100% !important;
    margin: 8px 0 !important;
  }

  .content mat-card.mat-card + mat-card.mat-card {
    margin-top: 16px !important;
  }

  .mat-card-header .mat-card-subtitle {
    line-break: anywhere !important;
  }

  .scheduled-actions {
    justify-content: space-between !important;
    position: fixed !important;
    bottom: 0 !important;
    z-index: 1 !important;
    color: #3f51b5 !important;
    width: 100% !important;
    right: 0 !important;
    padding-left: 88px !important;
    padding-right: 16px !important;
    height: 64px !important;
  }

  app-scheduled-processes-admin .list-container mat-accordion {
    height: calc(100% - 64px) !important;
  }

  .app-page .app-container .main-toolbar .mat-avatar .mat-stroked-button {
    margin-right: 0 !important;
    width: 52px !important;
    height: 52px !important;
  }

  .app-page
    .app-container
    .main-toolbar
    .mat-avatar
    .mat-stroked-button
    .mat-icon {
    margin-right: 0 !important;
  }

  .mat-stroked-button span.mat-button-wrapper {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  app-scheduled-processes-admin
    .list-container
    mat-accordion
    mat-accordion
    mat-expansion-panel.mat-expanded
    .mat-expansion-panel-content
    .mat-expansion-panel-body
    .floating-action {
    height: auto;
  }
}

//nuevo frame

body {
  .filter-menu {
    .mat-menu-content {
      padding: 0 !important;

      .menu-title {
        padding: 0 32px;
      }

      .menu-body {
        padding: 32px;
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        min-width: 280px;
      }

      .menu-footer {
        padding: 16px;
      }
    }
  }

  app-secret-user {
    mat-toolbar {
      background: none !important;
      min-width: 25vw;
      margin-top: -16px !important;
      margin-bottom: 16px !important;

      mat-toolbar-row {
        padding: 0 !important;

        .toolbar-line {
          width: 100%;

          .title {
            mat-icon {
              width: 40px;
              color: #3f51b5;
            }

            h2 {
              font-size: 16px;
              line-height: 20px;
              color: #3f51b5;
            }

            h5 {
              font-size: 12px;
              line-height: 16px;
            }
          }
        }
      }
    }

    .mat-dialog-content {
      padding: 16px 24px;
      position: relative;

      mat-progress-bar {
        width: 100%;
        left: 0;
      }

      mat-slide-toggle {
        min-height: 40px;
        margin-right: 4px;

        &.mat-checked {
          .mat-slide-toggle-content {
            font-weight: 700;
            color: rgba(0, 0, 0, 0.87);
          }
        }

        .mat-slide-toggle-bar {
          margin-right: 16px !important;
        }

        .mat-slide-toggle-content {
          font-size: 14px !important;
          text-transform: uppercase;
          color: rgba(0, 0, 0, 0.67);
        }
      }

      .warning {
        padding: 24px 0;

        h3 {
          margin: 0;
        }
      }

      mat-progress-spinner {
        margin: 0 auto;
      }
    }

    .mat-dialog-actions {
      margin-bottom: -10px !important;
    }
  }

  app-root {
    .app-page {
      app-home {
        .app-container {
          position: relative;
          height: 100vh;
          overflow: hidden;

          .app-workarea {
            height: 100vh;
            width: 100vw;
            position: relative;

            &.show-app-tools {
              .app-tools {
                transition: width 0.2s ease;
                width: 384px;
              }

              .app-main {
                padding-left: 384px;
                transition: padding-left 0.2s ease;
              }
            }

            .app-tools {
              width: 72px;
              height: 100%;
              position: absolute;
              background: #fff;
              transition: width 0.2s ease;
              overflow: hidden;
              left: 0;
              z-index: 3;

              .menu-content {
                width: 100%;
              }

              mat-nav-list {
                width: 100%;
                padding: 0 !important;

                .mat-list-item {
                  height: 72px !important;

                  &.active {
                    * {
                      color: #3f51b5;
                    }
                  }
                }

                &.navigation {
                  max-height: calc(100vh - 96px - 96px);
                  overflow-y: auto;
                  overflow-x: hidden;
                }

                &.menu-trigger {
                  .mat-list-item {
                    height: 96px !important;
                    background: #eee;
                  }

                  .mat-list-text {
                    color: #9e9e9e;
                    font-size: 14px;
                  }
                }

                &.menu-logoff {
                  .mat-list-item {
                    height: 96px !important;
                    background: #f7eef1;
                  }

                  * {
                    color: #ff4081;
                  }
                }
              }
            }

            //contenedor de vistas
            .app-main {
              height: 100%;
              width: 100%;
              background: #e0e0e0;
              overflow: visible;
              padding-left: 72px;
              transition: padding-left 0.2s ease;
              box-sizing: border-box;

              .impersonated-status {
                background: rgba(255, 255, 255, 0.08);
                height: 40px;
                border-radius: 20px;
                border: 1px solid rgba(255, 255, 255, 0.12);

                mat-icon {
                  width: 40px;
                  margin: 0 8px !important;
                }

                h3 {
                  margin: 0;
                  font-size: 14px;
                  line-height: 14px;
                }

                h5 {
                  margin: 0;
                  font-size: 12px;
                  line-height: 12px;
                  font-weight: 300;
                }

                .mat-icon-button {
                  .mat-button-wrapper {
                    height: 40px;
                    width: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .fa-stack {
                      height: 40px;
                      width: 40px;
                      display: flex;
                      align-items: center;
                      justify-content: center;

                      .fa-stack-1x {
                        line-height: 40px;
                      }

                      .fa-stack .fa-stack-2x {
                        line-height: 40px;
                        bottom: -1px;
                      }
                    }
                  }
                }
              }

              //ou admin
              .helper {
                width: calc(100% - 32px);
                transition: width 0.2s ease;
                height: 72px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 0 16px;
                box-sizing: border-box;
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);

                .status {
                  display: flex;
                  align-items: center;

                  button {
                    width: 40px !important;
                    min-width: unset;
                    display: flex;
                    justify-content: center;
                  }

                  b {
                    font-size: 13px;
                    margin-left: 8px;
                    color: #3f51b5;
                  }
                }

                .search {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  .field {
                    width: 40vw;
                    display: flex;
                    align-items: center;
                    position: relative;

                    .preffix {
                      position: absolute;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      left: 8px;
                      width: 40px;
                    }

                    input {
                      padding: 0 48px 0 52px;
                      height: 40px;
                      border-radius: 20px;
                      border: 1px solid rgba(0, 0, 0, 0.12) !important;
                      margin-right: 8px;
                      width: 100%;
                      outline: none;
                      background: #eeeeee;

                      &:focus {
                        background: #fff;
                      }
                    }

                    .suffix {
                      position: absolute;
                      right: 16px;
                      background: none !important;
                    }
                  }

                  button {
                    width: 40px !important;
                    min-width: unset;
                    display: flex;
                    justify-content: center;
                  }
                }
              }

              app-chapa {
                display: flex;
                width: 100%;
                height: calc(100% - 73px - 97px);
                justify-content: center;
                align-items: center;
                box-sizing: border-box;
                background: #e0e0e0;

                .inside-chapa {
                  height: 100%;
                  min-height: 25vw !important;

                  .large-icon {
                    height: 120px !important;
                    width: 120px !important;
                    border-radius: 70px;
                    font-size: 70px !important;
                    margin-bottom: 16px;
                    background: #3f51b5;
                    color: #e0e0e0;
                  }

                  h2,
                  h3 {
                    text-align: center;
                    margin: 0;
                    color: #3f51b5;
                  }

                  h4,
                  h5 {
                    margin: 0;
                    text-align: center;
                    max-width: 40vw;
                    font-weight: 400;
                    color: #616161;
                  }
                }
              }

              .ou-cards {
                display: flex;
                align-items: stretch;
                justify-content: flex-start;
                flex-wrap: wrap;
                padding: 8px 0 8px 8px;
                max-height: calc(100vh - 72px - 96px);
                overflow-y: scroll;
                box-sizing: border-box;

                .mat-card {
                  width: calc((100% - 64px) / 4);
                  margin: 8px;
                  box-sizing: border-box;
                  transition: width 0.2s ease;
                  cursor: pointer;

                  &.not-able {
                    .mat-card-info *:not(mat-card-subtitle) {
                      color: #9e9e9e !important;
                    }
                  }

                  &:hover:not(.active) {
                    background: #eee;

                    .mat-card-actions {
                      opacity: 1;
                      transition: opacity 0.2s ease;
                    }
                  }

                  mat-card-title.mat-card-title {
                    font-size: 18px;
                  }

                  .mat-card-actions {
                    display: flex;
                    justify-content: flex-end;
                    opacity: 0;
                    transition: opacity 0.2s ease;
                  }

                  &.active {
                    background: #3f51b5;
                    pointer-events: none;
                    cursor: default;

                    &.not-able {
                      background: #9e9e9e !important;

                      .mat-card-info *:not(mat-card-subtitle) {
                        color: #ffffff !important;
                      }
                    }

                    .mat-card-actions {
                      pointer-events: all;
                      opacity: 1;
                    }

                    * {
                      color: white;
                    }
                  }
                }
              }

              .ou-detail {
                position: absolute;
                width: 384px;
                background: #fff;
                height: 100%;
                right: -384px;
                top: 0;
                transition: right 0.2s ease;
                box-sizing: border-box;

                .title {
                  height: 96px;
                  padding: 16px;
                  box-sizing: border-box;
                  background: #eee;

                  .mat-icon {
                    width: 40px;
                  }

                  .text {
                    color: #3f51b5;
                  }
                }

                .side-container {
                  width: 100%;
                  height: calc(100% - 96px);
                  padding: 16px;
                  box-sizing: border-box;
                  overflow-y: auto;

                  mat-card {
                    mat-card-title {
                      font-size: 16px;
                      margin: 0;

                      & + mat-card-title {
                        font-size: 12px;
                      }
                    }

                    .side mat-card mat-card-subtitle {
                      font-size: 12px;
                      margin: 0;
                    }

                    & + mat-card {
                      margin-top: 16px;
                    }

                    &.card-side {
                      box-shadow: none;
                      border: 1px solid rgba(0, 0, 0, 0.12);

                      &.not-able {
                        & .card-side-info * {
                          color: #9e9e9e !important;
                        }
                      }

                      &:hover {
                        background: #eee;

                        .mat-card-actions {
                          opacity: 1;
                          transition: opacity 0.2s ease;
                        }
                      }

                      .mat-card-actions {
                        opacity: 0;
                        transition: opacity 0.2s ease;
                      }

                      .card-side-info *:not(mat-card-subtitle) {
                        color: #3f51b5;
                      }
                    }

                    &.card-main {
                      background: #3f51b5;

                      &.not-able {
                        background: #9e9e9e !important;
                      }

                      * {
                        color: white;
                      }
                    }
                  }
                }
              }

              .show-detail {
                .mat-progress-bar {
                  width: calc(100% - 384px - 72px);
                  transition: width 0.2s ease;
                }

                .main-toolbar {
                  width: calc(100% - 384px);
                  transition: width 0.2s ease;
                }

                .helper {
                  width: calc(100% - 384px - 32px);
                  transition: width 0.2s ease;
                }

                .ou-cards {
                  width: calc(100% - 384px);
                  transition: width 0.2s ease;

                  .mat-card {
                    width: calc((100% - 48px) / 3) !important;
                    transition: width 0.2s ease;
                  }
                }

                & + .ou-detail {
                  right: 0;
                  transition: right 0.2s ease;
                }
              }

              .demo-view {
                .main-toolbar {
                  background: #607d8b !important;
                }

                .ou-cards {
                  .mat-card {
                    &.active {
                      background: #607d8b !important;

                      &.not-able {
                        background: #9e9e9e !important;

                        .mat-card-info *:not(mat-card-subtitle) {
                          color: #ffffff !important;
                        }
                      }
                    }
                  }
                }

                & + .ou-detail {
                  .title {
                    .text {
                      color: #607d8b;
                    }
                  }

                  .side-container {
                    mat-card {
                      &.card-side {
                        .card-side-info *:not(mat-card-subtitle) {
                          color: #607d8b;
                        }
                      }

                      &.card-main {
                        background: #607d8b;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      app-organizational-unit-admin {
        .ou-admin {
          &.app-container {
            position: relative;
            box-sizing: border-box;
            height: 100vh;
            overflow: hidden;

            &.show-tools {
              .workarea {
                .tools {
                  width: 384px;
                  transition: width 0.2s ease;
                }

                .main {
                  padding-left: 384px;
                  transition: padding 0.2s ease;

                  mat-toolbar {
                    &.breadcrumb-nav {
                      transition: width 0.2s ease;
                      width: calc(100vw - 384px);
                    }
                  }
                }
              }
            }

            .workarea {
              height: 100vh;
              box-sizing: border-box;

              .tools {
                width: 72px;
                height: 100%;
                position: absolute;
                background: #fff;
                transition: width 0.2s ease;
                overflow: hidden;
                left: 0;
                z-index: 6;

                .menu-content {
                  width: 100%;
                }

                .mat-list-base {
                  width: 100%;
                  padding: 0 !important;

                  .mat-list-item {
                    height: 72px !important;

                    &.active {
                      * {
                        color: #3f51b5;
                      }
                    }
                  }

                  &.navigation {
                    max-height: calc(100vh - 96px - 96px);
                    overflow-y: auto;
                    overflow-x: hidden;
                  }

                  &.menu-trigger {
                    .mat-list-item {
                      height: 96px !important;
                      background: #eee;
                    }

                    .mat-list-text {
                      color: #9e9e9e;
                      font-size: 14px;
                    }
                  }

                  &.menu-version {
                    .mat-list-item {
                      height: 96px !important;
                      background: #e8eaf6;

                      .mat-list-text {
                        mat-icon {
                          width: 40px;
                        }
                      }
                    }

                    mat-icon,
                    b {
                      color: #3f51b5;
                    }
                  }
                }
              }

              .main {
                width: 100%;
                height: 100%;
                padding-left: 72px;
                transition: padding 0.2s ease;
                box-sizing: border-box;

                mat-toolbar {
                  &.main-toolbar {
                    .toolbar-line {
                      h5 {
                        opacity: 0;
                        line-height: 30px;
                        height: 30px;
                      }
                    }
                  }

                  &.breadcrumb-nav {
                    position: fixed;
                    z-index: 4;
                    height: 96px;
                    transition: height 0.2s ease;
                    width: calc(100vw - 72px);

                    &.shrink {
                      height: 20px;
                      top: 48px;
                      background: none !important;
                      pointer-events: none;
                      color: #fff;
                      transition: height 0.2s ease;

                      .breadcrumbs {
                        height: 100%;

                        .mat-icon-button {
                          opacity: 0;
                          pointer-events: none;
                        }

                        mat-icon.main-icon {
                          opacity: 0;
                        }
                      }
                    }

                    .breadcrumbs {
                      pointer-events: all;
                      height: 36px;

                      .mat-icon-button {
                        .mat-button-wrapper {
                          height: 40px;
                          width: 40px;
                          display: flex;
                          justify-content: center;
                          align-items: center;

                          .fa-stack {
                            height: 40px;
                            width: 40px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .fa-stack-1x {
                              line-height: 40px;
                            }

                            .fa-stack .fa-stack-2x {
                              line-height: 40px;
                              bottom: -1px;
                            }
                          }
                        }
                      }

                      .mat-button {
                        border-radius: 20px;

                        .mat-button-wrapper {
                          display: flex;
                          align-items: center;

                          .mat-icon {
                            margin-right: -16px;
                          }
                        }
                      }

                      .mat-icon {
                        width: 40px;
                      }
                    }

                    .breadcrumbs-actions {
                      pointer-events: all;
                    }
                  }
                }

                app-chapa {
                  display: flex;
                  width: 100%;
                  height: 100vh;
                  justify-content: center;
                  align-items: center;
                  padding-top: 96px;
                  box-sizing: border-box;
                  background: #e0e0e0;

                  .inside-chapa {
                    height: 100%;
                    min-height: 25vw !important;

                    .large-icon {
                      height: 120px !important;
                      width: 120px !important;
                      border-radius: 70px;
                      font-size: 70px !important;
                      margin-bottom: 16px;
                      background: #3f51b5;
                      color: #e0e0e0;
                    }

                    h2,
                    h3 {
                      text-align: center;
                      margin: 0;
                      color: #3f51b5;
                    }

                    h4,
                    h5 {
                      margin: 0;
                      text-align: center;
                      max-width: 40vw;
                      font-weight: 400;
                      color: #616161;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Override modales
.cdk-overlay-container {
  z-index: 5001 !important;
}

.dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 300px;
  border: 2px dashed #666;
  border-radius: 5px;
  background: white;
  margin: 10px 0;

  &.hovering {
    border: 2px solid #666;
    color: #dadada !important;
  }
}

.mat-list-icon {
  color: rgba(0, 0, 0, 0.54);
}

mat-icon.disabled {
  color: rgba(0, 0, 0, 0.27);
}

.apps-table {
  width: 100%;
}
.app-detail {
  .detail-block + .detail-block {
    display: none;
  }
}
